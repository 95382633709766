// components/FAQSection/styles.ts
import styled, { keyframes } from "styled-components";
import { Button } from "components/button";

export const Container = styled.div`
   margin: 0 auto;
   padding: 1.5rem;
   display: flex;
   justify-content: center;
`;
export const FAQContainer = styled.div`
   max-width: 840px;
   margin: 2.5rem 0;
   width: 100%;
   padding: 2rem;
   padding-bottom: 5rem;
   border-radius: 2rem;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`;

export const BackButtonContainer = styled.div`
   display: flex;
   padding: 1rem;
   width: 100%;
   justify-content: start;
   align-items: center;
`;

export const HeaderSection = styled.div`
   text-align: center;
   margin-bottom: 2rem;
`;

export const SuccessQuestion = styled.p`
   color: #464646;
   padding: 1.25rem;
   border-bottom: 1px solid #e2e8f0;
   & span {
      color: #808080;
      font-weight: 400;
      margin-right: 1rem;
   }
`;

export const SuccessAnswer = styled.p`
   color: #464646;
   font-weight: 400;
   padding: 1.25rem;
   line-height: 1.75;
`;

export const AISuccessBtnContainer = styled.p`
   display: flex;
   flex-direction: column;
   align-items: start;

   width: 100%;
   margin: 3rem 0rem;
   padding: 0rem 2rem;

   @media only screen and (min-width: 460px) {
      flex-direction: row;
      justify-content: end;
   }
   & > * {
      margin: 0.5rem;
   }
`;

export const SearchAnsContainer = styled.div`
   width: 100%;
   padding: 1rem;
   border: 1px solid #e2e8f0;
   border-radius: 8px;
   font-size: 16px;
   color: #464646;
   font-weight: 500;
`;

export const IconCircle = styled.div`
   width: 5rem;
   height: 5rem;
   background: #3578e5;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 auto 1rem;
   color: white;
`;

export const CircleAskAIBtn = styled(Button)`
   transform: scale(0.75);
   position: absolute;
   right: 0.75rem;
   top: 0.3rem;
   width: 4.5rem !important;
   height: 4.5rem !important;
   background: #3578e5;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 auto 1rem;
   color: white;
   & span {
      display: flex;
      align-items: center;
      justify-content: center;
   }
`;

export const Title = styled.h1`
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 0.5rem;
   color: #464646;
   @media only screen and (min-width: 460px) {
      font-size: 24px;
   }
`;

export const Subtitle = styled.p`
   color: #878787;
   font-size: 16px;
`;

export const SearchContainer = styled.form`
   position: relative;
   margin-bottom: 2rem;
`;

export const SearchInput = styled.input`
   width: 100%;
   padding: 0.75rem 1rem;
   height: 5rem;
   padding-right: 3rem;
   border: 1px solid #e2e8f0;
   border-radius: 8px;
   font-size: 14px;
   color: #464646;
   font-weight: 500;
   background-color: #f5f5f5;

   &:focus {
      outline: none;
      border-color: #3578e5;
      box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
   }
   @media only screen and (min-width: 460px) {
      font-size: 16px;
   }
`;
export const ErrorMessageAI = styled.p`
   width: 100%;
   font-size: 14px;
   color: red;
   margin: 0.5rem;
`;

export const SendButton = styled.button`
   background: none;
   border: none;
   cursor: pointer;
   padding: 0.5rem;
   color: #3578e5;

   &:hover {
      color: #2b579a;
   }
`;

export const FAQSection = styled.div`
   margin: 2rem 0 5rem 0;
   width: 100%;
   max-width: 586px;
`;

export const FAQTitle = styled.h2`
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 1rem;
   color: #464646;
   @media only screen and (min-width: 460px) {
      font-size: 16px;
   }
`;

export const FAQCard = styled.li`
   border: 1px solid #e2e8f0;
   border-radius: 8px;
   margin-bottom: 1.5rem;
   transition: all 0.2s ease;
   list-style: none;
   width: 100%;
   padding: 1rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-weight: 500;
   background: white;
   /* border: none; */
   cursor: pointer;
   text-align: left;
   font-size: 14px;
   color: #4a4a4a;

   &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background: #f8fafc;
   }
   @media only screen and (min-width: 460px) {
      font-size: 16px;
   }
`;

export const FAQButton = styled.div`
   width: 100%;
   padding: 1rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-weight: 500;
   background: white;
   border: none;
   cursor: pointer;
   text-align: left;
   font-size: 16px;
   color: #4a4a4a;

   &:hover {
      background: #f8fafc;
   }
`;

export const FAQContent = styled.div<{ isOpen: boolean }>`
   padding: ${(props) => (props.isOpen ? "1rem" : "0")};
   height: ${(props) => (props.isOpen ? "auto" : "0")};
   overflow: hidden;
   transition: all 0.2s ease;
   border-top: ${(props) => (props.isOpen ? "1px solid #e2e8f0" : "none")};
`;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

export const SkeletonWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 16px;
   padding: 16px;
   border: 1px solid #e0e0e0;
   border-radius: 8px;
   width: 300px;
   height: 400px;
   background-color: #f6f6f6;
`;

export const SkeletonElement = styled.div`
   width: "100%";
   height: "16px";
   background: linear-gradient(90deg, #e0e0e0 25%, #f6f6f6 50%, #e0e0e0 75%);
   background-size: 200% 100%;
   border-radius: 4px;
   animation: ${shimmer} 1.5s infinite;
`;

export const SkeletonCard = styled.div`
   width: 100%;
   /* max-width: 400px; */
   background-color: #f0f0f0;
   border-radius: 8px;
   padding: 20px;
   overflow: hidden;
`;

export const SkeletonTitle = styled.div`
   width: 60%;
   height: 24px;
   background-color: #e0e0e0;
   border-radius: 4px;
   margin-bottom: 16px;
`;

export const SkeletonContent = styled.div`
   width: 100%;
   height: 16px;
   background-color: #e0e0e0;
   border-radius: 4px;
   margin-bottom: 8px;

   &:last-child {
      margin-bottom: 0;
   }
   position: relative;

   &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%, #f0f0f0 100%);
      /* background-size: 468px 468px; */
      animation: ${shimmer} 3s linear infinite;
   }
`;

export const SkeletonButton = styled.div`
   width: 100px;
   height: 36px;
   background-color: #e0e0e0;
   border-radius: 4px;
   margin-top: 16px;
`;

export const MarkdownContainer = styled.div`
   /* font-family: "Roboto", sans-serif; */
   font-size: 16px;
   line-height: 1.6;
   color: #333;

   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      font-weight: 700;
      margin-top: 1.5em;
      margin-bottom: 0.5em;
   }

   h1 {
      font-size: 1.75em;
   }
   h2 {
      font-size: 1.5em;
   }
   h3 {
      font-size: 1.25em;
   }
   h4 {
      font-size: 1em;
   }
   h5 {
      font-size: 1em;
   }
   h6 {
      font-size: 0.875em;
   }

   p {
      margin-top: 1em;
      margin-bottom: 1em;
   }

   a {
      color: #007bff;
      text-decoration: none;

      &:hover {
         text-decoration: underline;
      }
   }

   ul,
   ol {
      padding-left: 2em;
      margin-top: 1em;
      margin-bottom: 1em;
   }

   li {
      margin-bottom: 0.5em;
   }

   code {
      font-family: "Courier New", monospace;
      background-color: #f5f5f5;
      padding: 0.2em 0.4em;
      border-radius: 4px;
   }

   pre {
      font-family: "Courier New", monospace;
      background-color: #f5f5f5;
      padding: 1em;
      border-radius: 4px;
      overflow-x: auto;
   }
`;
