// import React from "react";
import * as S from "styles/index/new-index.style";

const StatsBar = ({ pageStats }: { pageStats: { num: any; label: string }[] }) => (
   <S.StatsContainer>
      <S.StatsInnerContainer>
         {pageStats.map((item) => (
            <>
               <S.StatItem>
                  <S.StatNumber>{item.num}</S.StatNumber>
                  <S.StatLabel>{item.label}</S.StatLabel>
               </S.StatItem>
            </>
         ))}
      </S.StatsInnerContainer>
   </S.StatsContainer>
);

export default StatsBar;
