/* #### Generated By: http://font.download #### */

import { css } from "styled-components";

export const fontWeights = {
   light: "300",
   regular: "400",
   medium: "500",
   semiBold: "600",
   bold: "700",
   extraBold: "800",
   heavy: "900",
};

export const HeywowFontFamily = css`
   @font-face {
      font-family: "HeyWow";
      font-style: normal;
      font-weight: normal;
      src: local("HeyWow Regular"), url("/fonts/heywow-webfont/HeyWowRegular.woff") format("woff");
   }

   @font-face {
      font-family: "HeyWow";
      font-style: normal;
      font-weight: 400;
      src: local("HeyWow Book"), url("/fonts/heywow-webfont/HeyWowBook.woff") format("woff");
   }

   @font-face {
      font-family: "HeyWow";
      font-style: normal;
      font-weight: 300;
      src: local("HeyWow Light"), url("/fonts/heywow-webfont/HeyWowLight.woff") format("woff");
   }

   @font-face {
      font-family: "HeyWow";
      font-style: normal;
      font-weight: 500;
      src: local("HeyWow Medium"), url("/fonts/heywow-webfont/HeyWowMedium.woff") format("woff");
   }

   @font-face {
      font-family: "HeyWow";
      font-style: normal;
      font-weight: 600;
      src: local("HeyWow SemiBold"), url("/fonts/heywow-webfont/HeyWowSemiBold.woff") format("woff");
   }

   @font-face {
      font-family: "HeyWow";
      font-style: normal;
      font-weight: 700;
      src: local("HeyWow Bold"), url("/fonts/heywow-webfont/HeyWowBold.woff") format("woff");
   }

   @font-face {
      font-family: "HeyWow";
      font-style: normal;
      font-weight: 800;
      src: local("HeyWow ExtraBold"),
         url("/fonts/heywow-webfont/HeyWowExtraBold.woff") format("woff");
   }

   @font-face {
      font-family: "HeyWow";
      font-style: normal;
      font-weight: 900;
      src: local("HeyWow Heavy"), url("/fonts/heywow-webfont/HeyWowHeavy.woff") format("woff");
   }
`;
