/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useState, useEffect } from "react";
import axios from "axios";
import Link from "next/link";
import router from "next/router";
import { Button } from "components/button";
import LandingPageSearch from "components/search/LandingPageSearch";
import { SelectInput } from "components/select";
import { searchSchoolsAutosuggest } from "services/school";
import { CenterSearchResult, SearchResultContainer } from "styles/index/index.style";
import * as S from "styles/index/new-index.style";
import { CountriesList } from "utils/countries-list";
import { paramsObjectToQueryString } from "utils/generic-utils";
import { FeaturedSchools } from "../featured-schools";

const Featured = ({ featuredSchools }: { featuredSchools: any[] }) => {
   return (
      <S.FeaturedContainer>
         <S.TestShadowCircleTop />
         <S.FeaturedInnerContainer>
            <S.SectionContainer>
               <S.SectionTitle>
                  Your wards are a few clicks away from their dream school!
               </S.SectionTitle>
            </S.SectionContainer>
            <HomeFilters />
            <FeaturedSchools schools={featuredSchools} headingText="Featured Schools" />
         </S.FeaturedInnerContainer>
         {/* <S.SliderContainer> */}
         {/* </S.SliderContainer> */}
         {/* <S.TestInnerContainer>
            <S.TestSliderBtnContainer>
               <S.TestSliderBtn onClick={() => slider?.current?.slickPrev()}>
                  <BsArrowLeft color="white" size={16} />
               </S.TestSliderBtn>
               <S.TestSliderBtn onClick={() => slider?.current?.slickNext()}>
                  <BsArrowRight color="white" size={16} />
               </S.TestSliderBtn>
            </S.TestSliderBtnContainer>
         </S.TestInnerContainer> */}
      </S.FeaturedContainer>
   );
};

export default Featured;

function HomeFilters() {
   const [currentFilters, setCurrentFilters] = useState({});
   const [currentStates, setCurrentStates] = useState<any[]>([]);

   const [searchVal, setSearchVal] = useState<string>("");
   const [searchResult, setSearchResult] = useState<any>([]);
   const [isSearch, setIsSearch] = useState<boolean>(false);

   const findSchools = async (val) => {
      setSearchVal(val); // Update search value
      if (val === "") {
         setIsSearch(false);
         setSearchResult([]); // Clear search results
      } else {
         fetchSearchResults(val);
      }
   };

   // Function to fetch search results
   const fetchSearchResults = async (val) => {
      try {
         const response: any = await searchSchoolsAutosuggest(val);
         const {
            result: {
               data: { schools },
            },
         } = response;
         setSearchResult(schools);
         setIsSearch(true);
      } catch (err) {
         setIsSearch(false);
         return Promise.reject("error");
      }
   };

   async function handleSearch() {
      router.push({
         pathname: "/school/search",
         query: paramsObjectToQueryString(currentFilters).slice(1),
      });
   }

   const checkLocation = (data) => {
      switch (true) {
         case data === "Ghana":
            return router.push("/ghana");
         case data === "Kenya":
            return router.push("/kenya");
         default:
            return;
      }
   };

   const routeUser = async () => {
      const res = await axios.get(
         "https://api.geoapify.com/v1/ipinfo?&apiKey=9519d52007524b0bb17f626c9364422f"
      );
      const location = res.data.country.name;

      checkLocation(location);
   };
   useEffect(() => {
      routeUser();
   }, []);

   useEffect(() => {
      findSchools(searchVal);
   }, [isSearch, searchVal, currentStates]);

   function handleCurrentStates(countryName) {
      setCurrentStates([]);
      const currentState = CountriesList.find(
         (country) => country.name.toLowerCase() === countryName
      )?.states.map((el) => ({ value: el.name.toLowerCase(), label: el.name }));
      setCurrentStates(currentState as any[]);
   }

   function handleChange(e, type: string) {
      if (type === "tuition") {
         setCurrentFilters({ ...currentFilters, min: e?.value?.min, max: e?.value?.max });
      } else {
         setCurrentFilters({ ...currentFilters, [type]: e?.value });
      }
   }

   const tuitionOptions = [
      {
         value: { min: 0, max: 100000 },
         label: "₦0 -  ₦100k",
      },
      { value: { min: 100001, max: 200000 }, label: "₦101k - ₦200k" },
      { value: { min: 200001, max: 300000 }, label: "₦201k - ₦300k" },
      { value: { min: 300001, max: 400000 }, label: "₦301k - ₦400k" },
      { value: { min: 400001, max: 500000 }, label: "₦401k - ₦500k" },
      { value: { min: 500001, max: 1000000 }, label: "₦501k - ₦1M" },
      { value: { min: 1000001, max: 5000000 }, label: "₦1M+ " },
      // {
      //    value: { min: 0, max: 100000 },
      //    label: "₦0 -  ₦100,000",
      // },
      // { value: { min: 100001, max: 200000 }, label: "₦100,001 - ₦200,000" },
      // { value: { min: 200001, max: 300000 }, label: "₦200,001 - ₦300,000" },
      // { value: { min: 300001, max: 400000 }, label: "₦300,001 - ₦400,000" },
      // { value: { min: 400001, max: 500000 }, label: "₦400,001 - ₦500,000" },
      // { value: { min: 500001, max: 1000000 }, label: "₦500,001 - ₦1,000,000" },
      // { value: { min: 1000001, max: 5000000 }, label: "₦1,000,000  + " },
   ];

   const ratingOptions = [
      { value: 1, label: "⭐ Interesting" },
      { value: 2, label: "⭐⭐ Cool" },
      { value: 3, label: "⭐⭐⭐ Good" },
      { value: 4, label: "⭐⭐⭐⭐ Great" },
      { value: 5, label: "⭐⭐⭐⭐⭐ Excellent" },
   ];
   // <S.FeaturedFilterContainer>
   {
      /* <SearchMainContainer>
            <div style={{ marginTop: "50px" }}>
               <LandingPageSearch
                  handleSearch={(val) => findSchools(val)}
                  setShowSearch={setIsSearch}
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                  id="landing-page"
                  placeholder="Search schools, cities, categories etc"
               />
            </div>
            {isSearch && searchResult.length > 0 && (
               <CenterSearchResult>
                  <SearchResultContainer>
                     <div className="result-main">
                        {searchResult?.map((item, idx) => (
                           <div key={idx} className="result">
                              <Link href={`/school/${item?.slug}`}>{item?.name}</Link>
                           </div>
                        ))}
                     </div>
                  </SearchResultContainer>
               </CenterSearchResult>
            )}
         </SearchMainContainer>

         <FilterWrapper>
            <div className="main-container">
               <FilterContainer>
                  <div className="start">
                     <SelectInput
                        placeholder="Tuition per annum"
                        options={tuitionOptions}
                        onChange={(e: any) => handleChange(e, "tuition")}
                     />
                  </div>
                  <div className="end">
                     <SelectInput
                        placeholder="Rating"
                        options={ratingOptions}
                        onChange={(e: any) => handleChange(e, "rating")}
                     />
                  </div>
               </FilterContainer>
               <FilterContainer>
                  <div className="start">
                     <SelectInput
                        placeholder="Country"
                        options={CountriesList.map((c) => ({
                           label: c.name,
                           value: c.name.toLowerCase(),
                        }))}
                        onChange={(val: any) => {
                           handleChange(val, "country");
                           handleCurrentStates(val?.value);
                        }}
                     />
                  </div>
                  <div className="end">
                     <SelectInput
                        placeholder="State"
                        options={currentStates}
                        onChange={(e) => handleChange(e, "state")}
                        key={currentStates[0]?.value}
                     />
                  </div>
               </FilterContainer>
               <div className="landing-search-btn">
                  <Button
                     disabled={Object.keys(currentFilters).length === 0}
                     onClick={handleSearch}
                     className="find-school"
                     variant="success"
                  >
                     Find School
                  </Button>
               </div>
            </div>
         </FilterWrapper> */
   }

   //  </S.FeaturedFilterContainer>
   const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      // Handle search submission
   };

   return (
      <S.FeaturedSearchContainer>
         <S.FeaturedSearchForm onSubmit={handleSubmit}>
            <S.FeaturedSearchField>
               <label htmlFor="schools">Schools</label>

               <LandingPageSearch
                  handleSearch={(val) => findSchools(val)}
                  setShowSearch={setIsSearch}
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                  id="landing-page"
                  placeholder="Search schools, cities, categories etc"
               />
               {isSearch && searchResult.length > 0 && (
                  <CenterSearchResult>
                     <SearchResultContainer>
                        <div className="result-main">
                           {searchResult?.map((item, idx) => (
                              <div key={idx} className="result">
                                 <Link href={`/school/${item?.slug}`}>{item?.name}</Link>
                              </div>
                           ))}
                        </div>
                     </SearchResultContainer>
                  </CenterSearchResult>
               )}
            </S.FeaturedSearchField>

            <S.FeaturedSearchField>
               <label htmlFor="tuition">Tuition</label>
               <SelectInput
                  placeholder="Tuition per annum"
                  options={tuitionOptions}
                  onChange={(e: any) => handleChange(e, "tuition")}
               />{" "}
            </S.FeaturedSearchField>

            <S.FeaturedSearchField>
               <label htmlFor="rating">Rating</label>
               <SelectInput
                  placeholder="Rating"
                  options={ratingOptions}
                  onChange={(e: any) => handleChange(e, "rating")}
               />
            </S.FeaturedSearchField>

            <S.FeaturedSearchField>
               <label htmlFor="country">Country</label>
               <SelectInput
                  placeholder="Country"
                  options={CountriesList.map((c) => ({
                     label: c.name,
                     value: c.name.toLowerCase(),
                  }))}
                  onChange={(val: any) => {
                     handleChange(val, "country");
                     handleCurrentStates(val?.value);
                  }}
               />
            </S.FeaturedSearchField>

            <Button
               variant="primary"
               type="submit"
               disabled={Object.keys(currentFilters).length === 0}
               onClick={handleSearch}
               className="search_btn"
            >
               Search
            </Button>
         </S.FeaturedSearchForm>
      </S.FeaturedSearchContainer>
   );
}
