import styled from "styled-components";
import { space, layout, SpaceProps, LayoutProps, typography, TypographyProps } from "styled-system";
import { responsive } from "theme/reponsive";

type Props = SpaceProps & LayoutProps & TypographyProps;

export const HeadingOne = styled.h3<Props>`
   ${space}
   ${layout}
   ${typography}
`;
export const TextHeadingOne = styled(HeadingOne)`
   ${space}
   ${layout}
   ${typography}

   font-size: 23px;
   font-weight: 500;
   letter-spacing: -0.9px;
   color: #10223e;
   margin: 25px 0;

   ${responsive("$small")`
   margin: 18px 0;
   font-size: 1.8rem;
   // padding: 0 3rem ;
`}
`;

export const TextHeadingTwo = styled(HeadingOne)`
   ${space}
   ${layout}
   ${typography}

   font-size: 23px;
   font-weight: 500;
   letter-spacing: -0.9px;
   color: #10223e;
   margin: 25px 0;

   ${responsive("$small")`
   margin: 18px 0;
   font-size: 1.8rem;
`}
`;

export const SearchTextHeading = styled(HeadingOne)`
   ${space}
   ${layout}
   ${typography}

   font-size: 50px;
   font-weight: 600;
   letter-spacing: -0.9px;
   color: #10223e;
   margin: 25px 0;
   span {
      text-transform: capitalize;
   }

   ${responsive("$small")`
   margin: 18px 0;
   font-size: 24px;
`}
`;

export const NewTextHeadingOne = styled(HeadingOne)`
   ${space}
   ${layout}
   ${typography}

   font-family: "Heywow";
   font-size: 32px;
   font-weight: 600;
   letter-spacing: -0.9px;
   color: #10223e;
   margin: 25px 0;
   text-transform: capitalize;

   ${responsive("$small")`
   margin: 18px 0;
   font-size: 24px;
   // padding: 0 3rem ;
`}
`;

export const MarketTextHeadingOne = styled(HeadingOne)`
   ${space}
   ${layout}
   ${typography}

   font-size: 24px;
   font-weight: 600;
   letter-spacing: -0.9px;
   color: #10223e;
   margin: 25px 0;
   text-transform: capitalize;

   ${responsive("$small")`
   margin: 18px 0;
   font-size: 18px;
   // padding: 0 3rem ;
`}
`;

export const MarketTextHeadingTwo = styled(HeadingOne)`
   ${space}
   ${layout}
   ${typography}

   font-size: 24px;
   font-weight: 600;
   letter-spacing: -0.9px;
   color: #10223e;
   text-transform: capitalize;

   ${responsive("$small")`
   margin: 15px 0;
   font-size: 18px;
   // padding: 0 3rem ;
`}
`;

export const TooltipContainer = styled.div`
   position: relative;
   display: inline-block;
`;

export const TooltipContent = styled.div`
   visibility: hidden;
   width: 180px;
   color: #000;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   background-color: #f1f1f1;
   text-align: center;
   padding: 2px;
   border-radius: 4px;
   position: absolute;
   z-index: 1;
   bottom: 125%; /* Position the tooltip above the element */
   left: 50%;
   margin-left: -95px; /* Center the tooltip horizontally */
   opacity: 0;
   transition: opacity 0.3s;
   line-height: 15px;
   text-align: left;
   &::before {
      content: "";
      position: absolute;
      top: 100%; /* Adjust to move the bottom of the triangle under the box */
      left: 50%;
      margin-left: -5px; /* Adjust the margin to center the arrow */
      border-width: 5px;
      border-style: solid;
      border-color: #f1f1f1 transparent transparent transparent;
   }
`;

export const TooltipTrigger = styled.div`
   cursor: pointer;
   &:hover + ${TooltipContent} {
      visibility: visible;
      opacity: 1;
   }
`;
