import router from "next/router";
import Slider, { Settings } from "react-slick";
import { Button } from "components/button";
// import TriangleTooltip from "components/triangle-tooltip/triangle-tooltip";
import { NewTextHeadingOne } from "styles/text-heading-one";
import { Div } from "styles/typography";
import {
   FeaturedSchoolsContainer,
   SchoolCards,
   SemiPaddedFeaturedSchoolsContainer,
} from "./landing-page.style";
import { NewSchoolCard } from "./school-card-new";

interface IFeaturedSchools {
   headingText?: string;
   schools: any[];
   noPadding?: boolean;
   noSeeMore?: boolean;
   description?: string;
}

export const FeaturedSchools = ({
   schools,
   headingText,
   // noPadding,
   noSeeMore,
}: IFeaturedSchools) => {
   const settings: Settings = {
      dots: false,
      autoplay: true,
      slidesToShow: 4,
      speed: 1000,
      autoplaySpeed: 4000,
      initialSlide: 0,
      infinite: true,
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               arrows: true,
            },
         },
         {
            breakpoint: 980,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               centerMode: true,
               centerPadding: "40",
               initialSlide: 3,
            },
         },

         {
            breakpoint: 560,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            },
         },
      ],
   };

   return (
      <FeaturedSchoolsContainer>
         <SemiPaddedFeaturedSchoolsContainer>
            <Div display="flex" alignItems="center" justifyContent="space-between">
               <div className="flex-area">
                  <NewTextHeadingOne>{headingText}</NewTextHeadingOne>
                  {/* <TriangleTooltip text="These are top vetted & verified schools that have subscribed to our promotional services.">
                  <img src="/landing-page/tooltipcircle.png" alt="tooltip" />
                  </TriangleTooltip> */}
               </div>

               {!noSeeMore && (
                  <Button
                     variant="link"
                     size="small"
                     onClick={() =>
                        router.push({
                           pathname: "/school/search",
                           query: {
                              isFeatured: "yes",
                           },
                        })
                     }
                  >
                     See More
                  </Button>
               )}
            </Div>
         </SemiPaddedFeaturedSchoolsContainer>
         <SchoolCards>
            <Slider draggable {...settings} className="card-slider">
               {schools?.map((sch) => (
                  <NewSchoolCard key={sch.slug} data={sch} />
               ))}
            </Slider>
         </SchoolCards>
      </FeaturedSchoolsContainer>
   );
};
