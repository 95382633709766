import styled from "styled-components";
import { Button } from "components/button";

export const LandingPageContainer = styled.div`
   max-width: 1200px;
   margin: 0 auto;
   font-family: "Heywow";
`;

export const SectionTitle = styled.h2`
   font-size: 3.25rem;
   margin: 2rem 0;
   text-align: center;
   max-width: 680px;
   color: #434249;
   line-height: 1.2;

   span {
      color: #264ffa;
      padding: 0 1rem;
   }
   @media (max-width: 768px) {
      margin: 1.5rem 0;
      font-size: 2.25rem;
   }
`;

export const SectionContainer = styled.div`
   padding: 1.5rem 0;
   width: 100%;
   display: flex;
   justify-content: center;

   @media (max-width: 768px) {
      padding: 2.5rem 0;
   }
`;

export const HeroContainer = styled.div`
   background: linear-gradient(to bottom, rgba(38, 80, 250, 0.41), white);
`;

export const InnerHeroContainer = styled(LandingPageContainer)`
   padding: 4rem 2rem 0;
   min-height: 600px;
   display: grid;
   align-items: center;
   @media (min-width: 768px) {
      grid-template-columns: 1.35fr 1fr;
      padding: 6rem 2rem;
      height: 100dvh;
   }
   gap: 2rem;
`;

export const HeroContent = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 2rem;
   margin: 2rem 0;
   h3 {
      color: #434249;
      font-weight: 600;
      font-size: 1.75rem;
      margin-top: 2rem;
   }
   @media (min-width: 768px) {
      margin: 2rem 0;
   }
`;

export const HeroTitle = styled.h1`
   font-size: 2rem;
   line-height: 1.2;
   span {
      color: #264ffa;
      font-weight: 900;
   }
   @media (min-width: 768px) {
      font-size: 3.75rem;
   }
`;

export const HeroSubtitle = styled.p`
   font-size: 1.75rem;
   color: #585858;
   max-width: 600px;
   @media (min-width: 768px) {
      /* font-size: 1%.; */
   }
`;

export const SearchContainer = styled.form`
   display: flex;
   gap: 1rem;
   margin-top: 0.5rem;
`;

export const SearchInput = styled.div`
   flex: 1;
   position: relative;

   /* input {
      width: 100%;
      padding: 1rem 1rem 1rem 3rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
   }

   svg {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      color: #666;
   } */
`;

export const SearchButton = styled(Button)`
   padding: 0.75rem 1.75rem;

   @media (min-width: 768px) {
      padding: 1.4rem 2.4rem;
   }
`;

export const SearchButtonSpan = styled.span`
   display: inline-flex;
   justify-content: space-between;
   align-items: center;

   span {
      display: none;
   }
   @media (min-width: 768px) {
      & * {
         margin-right: 1rem;
      }
      span {
         display: block;
      }
   }
`;

export const HeroImage = styled.div`
   transform: translateY(0.7rem);
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
      border: 4px solid #264ffa;
      max-width: auto;
   }
   @media (min-width: 768px) {
      transform: translateY(0);
   }
`;

export const StatsContainer = styled.div`
   background: #264ffa;
`;

export const StatsInnerContainer = styled(LandingPageContainer)`
   display: grid;
   color: white;
   padding: 4rem 1rem;
   text-align: center;
   /* grid-template-columns: 1fr; */
   grid-template-columns: repeat(3, 1fr);
   gap: 0.75rem;
   @media (min-width: 768px) {
      gap: 2rem;
      padding: 4rem 2rem;
   }
`;

export const StatItem = styled.div`
   &:nth-child(even) {
      padding: 0 0.5rem;
      border-right: 2px solid rgba(255, 255, 255, 0.75);
      border-left: 2px solid rgba(255, 255, 255, 0.75);
   }

   @media (min-width: 768px) {
      /* padding-bottom: 1rem; */
      padding: 0 1rem;
   }
`;

export const StatNumber = styled.h2`
   font-weight: 700;
   margin-bottom: 1rem;
   font-size: 1.75rem;
   @media (min-width: 768px) {
      font-size: 3rem;
   }
`;

export const StatLabel = styled.p`
   font-size: 1rem;
   font-weight: 500;
   border-top: 1rem;
   opacity: 0.9;
   letter-spacing: 0.005px;
   @media (min-width: 768px) {
      font-size: 1.75rem;
   }
`;

// components/FeatureSection/styles.ts
export const FeaturesContainer = styled.div`
   background: #f2f2f2;
`;

export const FeaturesInnerContainer = styled(LandingPageContainer)`
   padding: 8rem 2rem 0rem;
`;

export const FeatureCard = styled.div`
   display: flex;
   flex-direction: column-reverse;
   margin-bottom: 6rem;
   align-items: start;

   /* justify-content: space-between; */

   &:nth-child(even) {
      flex-direction: column-reverse;
      margin-bottom: 0rem;
      /* margin-top: 4rem; */
   }
   @media (min-width: 768px) {
      flex-direction: row;
      margin-bottom: 4rem;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      &:nth-child(even) {
         flex-direction: row-reverse;
      }
   }
`;

export const FeatureImage = styled.div`
   position: relative;
   width: 100%;
   aspect-ratio: 1.25/1;
   @media (min-width: 768px) {
      width: 400px;
   }
`;

export const FeatureContent = styled.div`
   max-width: 500px;
   margin-bottom: 2rem;
   @media (min-width: 768px) {
      margin-top: 0rem;
   }
`;

export const FeatureTag = styled.span`
   display: inline-block;
   background: #264ffa1a;
   color: #264ffa;
   padding: 0.5rem 1rem;
   border-radius: 4px;
   margin-bottom: 2.5rem;
   font-size: 1.5rem;
   font-weight: 600;
`;

export const FeatureTitle = styled.h2`
   font-size: 3.25rem;
   line-height: 1.2;
   margin-bottom: 1rem;

   @media (max-width: 768px) {
      font-size: 2rem;
   }
`;

export const FeatureDescription = styled.p`
   color: #666;
   line-height: 1.6;
   margin-bottom: 2rem;
`;

export const LearnMoreButton = styled.a`
   display: inline-flex;
   align-items: center;
   gap: 0.5rem;
   color: #264ffa;
   text-decoration: none;
   font-weight: 500;

   &:hover {
      color: rgb(24, 53, 170);
   }
   span {
      border-radius: 100%;
      height: 2.75rem;
      width: 2.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #264ffa;
   }
`;
export const DownloadContainer = styled.section`
   background: #f2f2f2;
   padding: 8rem 0;

   @media (min-width: 768px) {
      padding: 8rem 2rem;
   }
`;

// Download app banner
export const DownloadInnerContainer = styled(LandingPageContainer)`
   overflow: hidden;
   position: relative;
   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
   display: flex;
   flex-direction: column;
   align-items: start;
   justify-content: space-between;
   padding: 3rem 2rem;
   margin-bottom: 1.25rem;
   background: #264ffa;
   /* padding: 4rem 2rem; */
   @media (min-width: 640px) {
      border-radius: 1.5rem;
      flex-direction: row;
      padding: 3rem 5rem;
      align-items: center;
   }

   &::before,
   &::after {
      content: "";
      position: absolute;
      border-radius: 50%;
      background: #092fcd;
      z-index: 10;
   }

   &::before {
      width: 22rem;
      height: 22rem;
      bottom: 80%;
      right: -8rem;
   }

   @media (min-width: 960px) {
      &::before {
         width: 24rem;
         height: 24rem;
         bottom: 80%;
         right: -8rem;
      }
      &::after {
         width: 20rem;
         height: 20rem;
         top: 85%;
         left: 25%;
      }
   }
`;

export const DownloadContent = styled.div`
   flex: 2;
   display: flex;
   flex-direction: column;
   gap: 1.25rem;

   p {
      color: #e2e2e2;
      font-weight: 500;
      font-size: 1.35rem;
      margin-top: 1.5rem;
   }

   h1 {
      color: white;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.5;

      @media (min-width: 768px) {
         /* font-size: 2rem; */
      }

      @media (min-width: 1024px) {
         font-size: 2.5rem;
      }
   }
`;

export const DownloadLinksContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 0.75rem;

   @media (min-width: 460px) {
      flex-direction: row;
      align-items: flex-end;
   }

   a {
      background-color: #022b69;
      color: white;
      border-radius: 0.375rem;
      padding: 0.75rem;
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      text-decoration: none;
   }
`;

export const DownloadImageWrapper = styled.div`
   flex: 4;
   display: flex;
   justify-content: center;
   /* padding: 0 2rem; */

   img {
      transform: translateY(3rem);
      width: 90%;
      /* padding: 0 2rem; */
   }
   @media (min-width: 768px) {
      img {
         width: auto;
      }
      justify-content: flex-end;
   }
`;

// footer styles

export const FooterContainer = styled.footer`
   background-color: #264ffa;
   color: white;
   padding: 6rem 2rem;
`;
export const InnerFooterContainer = styled(LandingPageContainer)`
   & > div {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      /* margin: 2rem 0; */
   }
   .first-section {
      margin: 2rem 0 4rem;
   }
   @media (min-width: 768px) {
      text-align: left;
      & > div {
         /* align-items: center; */
         flex-direction: row;
      }
   }
`;

export const LogoWrapper = styled.div`
   margin-bottom: 2rem;
`;

export const DownloadContentFooter = styled(DownloadContent)`
   flex: none;
   margin-top: 2rem;
   p {
      margin-top: 0rem;
   }
   @media (min-width: 768px) {
      margin-top: 0rem;
   }
`;

export const NavigationContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 4rem;

   @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
   }
`;

export const NavigationSection = styled.div`
   display: flex;
   flex-direction: column;

   @media (min-width: 768px) {
      text-align: left;
   }
`;

export const NavTitle = styled.h3`
   font-size: 1.75rem;
   font-weight: 600;
   margin-bottom: 1.5rem;
`;

export const NavLink = styled.a`
   color: white !important;
   text-decoration: none !important;
   margin-bottom: 1.25rem;
   font-size: 1.5rem;

   &:hover {
      text-decoration: underline;
   }
`;

export const SocialCopyrightContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: start;

   @media (min-width: 768px) {
      text-align: left;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
   }
`;

export const SocialIcons = styled.div`
   display: flex;
   flex-wrap: wrap;
   gap: 1.5rem;
   margin-top: 2rem;
`;

export const SocialIconLink = styled.a`
   color: white;
   display: inline-block;
   display: inline-flex;
   align-items: center;
   gap: 0.5rem;
   text-decoration: none;

   &:first-child {
      gap: 0.15rem;
      transform: translateY(-1px);
      span {
         transform: translateY(1.5px);
      }
   }
   /* svg {
      fill: white;
      transition: fill 0.3s;
   }

   &:hover svg {
      fill: #f39c12;
   } */
`;

export const ExtraSection = styled.div`
   margin: 1rem 0;
   /* text-align: center; */
   display: flex;
   flex-direction: column;
   gap: 0rem;
`;

export const CopyrightSection = styled.div`
   margin-top: 4rem;
   padding-top: 2rem;
   text-align: center;
   border-top: 1px solid #e5e5e5;
   width: 100%;
   p {
      font-size: 1.5rem;
      color: #e5e5e5;
   }
   @media (min-width: 768px) {
      border-top: none;
      width: auto;
      margin-top: 2rem;
      padding-top: 0rem;
   }
`;

// Testimonial styles
export const TestContainer = styled.div`
   background: #f8f8f8;
   position: relative;
   overflow: hidden;
`;

export const TestInnerContainer = styled(LandingPageContainer)`
   padding: 4rem 2rem;
`;

export const TestShadowCircleTop = styled.div`
   position: absolute;
   width: 128px;
   height: 128px;
   border-radius: 50%;
   background: radial-gradient(circle at center, white, #2750fa);
   box-shadow: 14px 16px 80px 80px rgb(39 80 250 / 36%);
   top: -180px;
   z-index: 10;
   right: calc(50% - 64px);
`;

export const TestCard = styled.div`
   background-color: #e9f1ff;
   border: 1px solid #cccccc;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
   padding: 24px;
   padding-top: 32px;
   display: flex;
   flex-direction: column;
   justify-content: start;
   height: 320px;
   margin: 0 8px;
`;

export const TestText = styled.p`
   color: #434249;
   margin: 4px 0;
`;

export const TestAuthorContainer = styled.div`
   display: flex;
   align-items: center;
   gap: 2rem;
   margin-bottom: 8px;
`;

export const TestAvatarPlaceholder = styled.span`
   width: 40px;
   height: 40px;
   background-color: #d9d9d9;
   border: 1px solid #cccccc;

   /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
   border-radius: 50%;
`;

export const TestAuthorDetails = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   gap: 4px;
`;

export const TestAuthorName = styled.h3`
   color: black;
   font-weight: bold;
   margin: 0;
`;

export const TestAuthorRole = styled.p`
   color: #848484;
   font-size: 14px;
   margin: 0;
`;
export const SliderContainer = styled.div`
   margin: 2rem 0;
`;

export const TestSliderBtn = styled.button`
   display: inline-flex;
   align-items: center;
   gap: 0.5rem;
   color: #264ffa;
   text-decoration: none;
   font-weight: 500;
   border-radius: 100%;
   height: 4rem;
   width: 4rem;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #264ffa;
   cursor: pointer;
   box-sizing: border-box;
   /* display: inline-block; */
   border: none;
   text-decoration: none;

   &:hover,
   &:active {
      opacity: 0.7;
   }

   &:disabled,
   &:disabled:active,
   &:disabled:hover {
      cursor: not-allowed;
      opacity: 0.4;
   }
`;
export const TestSliderBtnContainer = styled.div`
   margin: 2rem 0;
   display: flex;
   align-items: center;
   justify-content: end;
   gap: 2rem;
`;

// Testimonial styles
export const AdsContainer = styled.div`
   background: white;
`;

export const AdsInnerContainer = styled(LandingPageContainer)`
   padding: 4rem 2rem 6rem;
`;

export const FeaturedContainer = styled.div`
   background: #fff;
   position: relative;
   overflow: hidden;
`;

export const FeaturedInnerContainer = styled(LandingPageContainer)`
   padding: 4rem 2rem 12rem 2rem;
`;

export const FeaturedFilterContainer = styled.div`
   display: flex;
   gap: 2rem;
`;

export const FeaturedSearchContainer = styled.div`
   padding: 4rem 2rem;
   text-align: center;
   max-width: 1200px;
   margin: 0 auto;

   @media (max-width: 768px) {
      padding: 2rem 1rem;
   }
`;

export const FeaturedSearchTitle = styled.h2`
   font-size: 2.5rem;
   color: #333;
   margin-bottom: 2rem;

   @media (max-width: 768px) {
      font-size: 1.8rem;
   }
`;

export const FeaturedSearchForm = styled.form`
   display: grid;
   grid-template-columns: 1.75fr repeat(2, 1fr) 0.75fr auto;
   gap: 2rem;
   background: white;
   padding: 2rem;
   border-radius: 8px;
   box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
   border: 1px solid #e5e5e5;
   align-items: end;

   @media (max-width: 968px) {
      grid-template-columns: 1fr 1fr;
   }

   @media (max-width: 576px) {
      grid-template-columns: 1fr;
   }
   .search_btn {
      max-height: 50px;

      &:disabled {
         opacity: 1;
      }
   }
`;

export const FeaturedSearchField = styled.div`
   position: relative;
   text-align: left;
   &.search-input {
      min-width: 30;
   }
   label {
      display: block;
      font-size: 1.5rem;
      color: #434249;
      font-weight: 500;
      margin-bottom: 0.5rem;
   }

   input,
   select {
      width: 100%;
      padding: 0.75rem;
      /* border: none; */
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1.5rem;
      color: #666;
      /* background: white; */

      &:focus {
         outline: none;
         border-color: #4169e1;
      }

      &::placeholder {
         color: #999;
      }
   }
`;

export const FeaturedSearchButton = styled.button`
   background: #4169e1;
   color: white;
   border: none;
   border-radius: 4px;
   padding: 0.75rem 2rem;
   font-weight: 500;
   cursor: pointer;
   height: fit-content;
   margin-top: auto;

   &:hover {
      background: #3154b3;
   }

   @media (max-width: 968px) {
      grid-column: 1 / -1;
   }
`;
