import Select from "react-select";
import { Div, ErrorP, InputFieldWrapperDiv, Label } from "components/input/input.style";
import { ISelectInput } from "./i-select";
import { AfredSelectInputComponentStyle } from "./select-input.styles";

const AfredSelectInput = ({
   label,
   error,
   banner,
   required,
   labelColor,
   whiteSpace,
   key,
   ...rest
}: ISelectInput) => {
   return (
      <Div>
         <InputFieldWrapperDiv>
            <AfredSelectInputComponentStyle banner={banner} error={!!error} className="other-selct">
               {label && (
                  <Label
                     className="select-label"
                     style={{ color: labelColor }}
                     whiteSpace={whiteSpace ? whiteSpace : false}
                  >
                     {`${label}`} <span style={{ color: "red" }}>{required ? "*" : ""}</span>
                  </Label>
               )}
               <Select
                  styles={{
                     menuPortal: (base) => ({ ...base, zIndex: 5000 }),
                  }}
                  classNamePrefix="react-select"
                  className="red"
                  menuPortalTarget={typeof window !== "undefined" ? window.document.body : null}
                  components={{ IndicatorSeparator: () => null }}
                  {...rest}
               />
               {error && (
                  <ErrorP
                     initial="pageInitial"
                     animate="pageAnimate"
                     exit="pageExit"
                     variants={{
                        pageInitial: {
                           opacity: 1,
                           x: 0,
                           y: -5,
                        },
                        pageAnimate: {
                           opacity: 1,
                           x: 0,
                           y: 0,
                        },
                        pageExit: {
                           opacity: 0,
                        },
                     }}
                  >
                     {error}
                  </ErrorP>
               )}
            </AfredSelectInputComponentStyle>
         </InputFieldWrapperDiv>
      </Div>
   );
};

AfredSelectInput.defaultProps = {
   label: "",
};

export { AfredSelectInput };
