/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Slider, { Settings } from "react-slick";
import * as S from "styles/index/new-index.style";

const settings: Settings = {
   dots: false,
   autoplay: true,
   slidesToShow: 4,
   speed: 1000,
   autoplaySpeed: 4000,
   arrows: false,
   initialSlide: 0,
   infinite: true,

   responsive: [
      // {
      //    breakpoint: 2000,
      //    settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 1,
      //       arrows: false,
      //       centerMode: true,
      //       centerPadding: "40",
      //       initialSlide: 3,
      //    },
      // },
      {
         breakpoint: 960,
         settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
         },
      },
      {
         breakpoint: 768,
         settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
         },
      },
      {
         breakpoint: 560,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
         },
      },
   ],
};

export type TestimonialCardType = {
   id: string;
   content: string;
   name: string;
   position?: string;
   imageUrl?: string;
};

const Testimonial = ({ testimonials }: { testimonials: TestimonialCardType[] }) => {
   const slider: any = useRef(null);

   return (
      <S.TestContainer>
         <S.TestShadowCircleTop />
         <S.TestInnerContainer>
            <S.SectionContainer>
               <S.SectionTitle>
                  We've supported more than <span>500k</span>
                  parents, see what some of them say about us
               </S.SectionTitle>
            </S.SectionContainer>
         </S.TestInnerContainer>
         <S.SliderContainer>
            <Slider ref={slider} draggable accessibility {...settings}>
               {[
                  testimonials?.map((t: TestimonialCardType) => (
                     <TestimonialCard key={t.id} testimonial={t} />
                  )),
               ]}
            </Slider>
         </S.SliderContainer>
         <S.TestInnerContainer>
            <S.TestSliderBtnContainer>
               <S.TestSliderBtn onClick={() => slider?.current?.slickPrev()}>
                  <BsArrowLeft color="white" size={16} />
               </S.TestSliderBtn>
               <S.TestSliderBtn onClick={() => slider?.current?.slickNext()}>
                  <BsArrowRight color="white" size={16} />
               </S.TestSliderBtn>
            </S.TestSliderBtnContainer>
         </S.TestInnerContainer>
      </S.TestContainer>
   );
};

export default Testimonial;

const TestimonialCard = ({ testimonial }: { testimonial: TestimonialCardType }) => {
   const { content, name } = testimonial;

   return (
      <S.TestCard>
         <S.TestAuthorContainer>
            {/* Uncomment and use Next.js Image for avatar when available */}
            {/* <Image
           src="/placeholder-avatar.png"
           alt={author}
           width={40}
           height={40}
           style={{ borderRadius: '50%', marginRight: '16px' }}
         /> */}
            <S.TestAvatarPlaceholder />
            <S.TestAuthorDetails>
               <S.TestAuthorName>{name}</S.TestAuthorName>
               {/* <S.TestAuthorRole>{position}</S.TestAuthorRole> */}
            </S.TestAuthorDetails>
         </S.TestAuthorContainer>
         <S.TestText>{content}</S.TestText>
      </S.TestCard>
   );
};
