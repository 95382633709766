import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import { responsive } from "theme/reponsive";

export const FeaturedSchoolsContainer = styled.div`
   margin-top: 32px;
   button {
      border-radius: 8px;
      padding: 5px;
   }
   .flex-area {
      display: flex;
      align-items: center;
      gap: 10px;
   }
   ${responsive("$small")`
`}
`;

export const DiscountedSchoolContainer = styled.div`
   margin-top: 70px;
   @media screen and (max-width: 468px) {
      margin-top: 40px;
   }
`;

export const BlogPostContainer = styled.div`
   margin-top: 70px;
   button {
      padding: 5px;
      border-radius: 8px;
   }
   @media screen and (max-width: 468px) {
      margin-top: 0px;
   }
`;

export const AddSchoolBannerContainer = styled.div`
   margin: 90px 0;
   background-color: ${({ theme }) => theme.colors?.primary};
   color: #fff;
   padding: 70px;
   max-height: 500px;
   text-align: center;
   border-radius: 10px;

   @media screen and (max-width: 468px) {
      padding: 30px;
   }

   h1 {
      margin-bottom: 20px;
      font-size: 35px;

      @media screen and (max-width: 468px) {
         font-size: 22px;
         line-height: 3.4rem;
      }
   }

   p {
      font-weight: 400;
      font-size: 1.8rem;

      @media screen and (max-width: 468px) {
         font-size: 1.5rem;
      }
   }

   button {
      padding: 10px 20px;
      outline: none;
      border: none;
      display: inline-block;
      margin-top: 20px;
      color: ${({ theme }) => theme.colors?.primary};
      background-color: #fff;
      font-weight: 600;
      border-radius: 5px;
   }
`;

export const SchoolCardContainer = styled.div`
   background: #fff;
   width: 100%;
   height: 484px;
   overflow: hidden;
   cursor: pointer;
   position: relative;

   box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
   margin: 10px 0px;
   border-radius: 7px;
   transition: transform 300ms ease-out;
`;

export const SchoolImageContainer = styled.div`
   width: 100%;
   height: 195px;
   overflow: hidden;
   position: relative;
   background-color: #000;

   .school-type {
      font-size: 10px;
      color: #fff;
      text-transform: capitalize;
      font-weight: 500;
      position: absolute;
      bottom: 10px;
      background-color: #da6c08;
      left: 10px;
      padding: 0 4px;
      margin: 0;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
   }

   .analytics {
      font-size: 10px;
      text-transform: capitalize;
      color: #fff;
      font-weight: 500;
      position: absolute;
      bottom: 10px;
      background-color: #06152d;
      right: 10px;
      padding: 0 4px;
      margin: 0;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 5px;
      .flex-analytics {
         display: flex;
         align-items: center;
         gap: 2px;
      }
   }

   .discount {
      position: absolute;
      left: 2;
      top: 10px;
      padding: 3px 10px;
      background: #ffa80ae3;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);

      z-index: 10;
      color: #fff;
      font-weight: 600;
      font-size: 1.4rem;
   }
`;

export const SchoolDetails = styled.div`
   background-color: #fff;
   overflow: hidden;
   border-left: none;
   /* height: 220px; */
   padding: 12px;

   .school-name-check {
      margin: 15px 0 0px 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .verified-flag {
         transform: translateX(-10px);
      }
   }

   .rating-area {
      display: flex;
      align-items: center;
      gap: 10px;
      p {
         font-size: 14px;
         color: #805e00;
         font-weight: 700;
      }
   }

   h5 {
      color: #6d7a98;
      font-size: 14px;
      font-weight: 500;
   }

   h4 {
      font-size: 18px;
      font-weight: 600;
      color: #444444;
      white-space: pre-line;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      margin: 0 0 5px 0;
   }

   .school-info {
      font-size: 13.5px;
      font-weight: 400;
      color: #264ffa;
      &.address {
         text-transform: capitalize;
      }

      &.type {
         font-weight: 500;
         text-transform: capitalize;
      }

      &.price {
         color: #46d3c2;
         font-weight: 600;
         font-size: 14px;
      }
   }

   @media screen and (max-width: 768px) {
      h4 {
         font-size: 15.5px;
      }
      h5 {
         font-size: 12px;
      }
      .school-info {
         font-size: 11.5px;
         &.price {
            font-size: 14px;
         }
      }
   }
`;

export const SemiPaddedFeaturedSchoolsContainer = styled.div<{ noPadding?: boolean }>`
   padding: 0;

   @media screen and (min-width: 768px) {
      padding: ${({ noPadding }) => (noPadding ? 0 : "0 4.5rem 0 4rem; ")};
   }
`;

export const SchoolCards = styled.div<{ noPadding?: boolean }>`
   width: 100%;
   overflow-x: hidden;
   margin-top: 15px;
   padding: 0;

   @media screen and (min-width: 768px) {
      padding: ${({ noPadding }) => (noPadding ? 0 : "0 4.5rem 0 4rem; ")};
   }
   .slick-arrow .slick-prev {
      background-color: #264ffa !important;
   }

   .card-slider {
      margin: 0;
      padding: 0px !important;

      ${responsive("$xsmall")`
         width: 100%;
      `}
   }
`;

export const SchoolCardFooter = styled.div`
   display: flex;
   align-items: center;
   position: absolute;
   bottom: 3%;
   button {
      border-radius: 8px;
      padding: 2px;
   }
`;

export const BannerCards = styled.div`
   display: flex;
   width: 30%;
   flex-direction: column;
   gap: 30px;
   overflow: hidden;
   margin-top: 20px;

   ${responsive("$medium")`
      grid-template-columns: repeat(2, 1fr);
   `}

   ${responsive("$xsmall")`
      overflow: scroll;
      display: flex;
      justify-content: space-between;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 30px;
      margin-top: 30px;
      width: 100%;
   `}
`;

export const BannerCard = styled.div<{ url?: string }>`
   min-height: 361px;
   width: 100%;
   background-image: url(${({ url }) => url && url});
   border-radius: 5px;
   background-position: center;
   background-size: cover;

   display: flex;
   align-items: flex-end;
   color: #fff;
   font-weight: 600;
   transition: transform 300ms ease;
   cursor: pointer;

   &:hover {
      transform: translateY(10px);
   }

   @media screen and (max-width: 468px) {
      min-width: 280px;
   }

   h4 {
      padding-bottom: 8px;
      padding-left: 15px;
      font-size: 2rem;

      @media screen and (max-width: 468px) {
         font-size: 1.8rem;
      }
   }
   h6 {
      padding-bottom: 30px;
      padding-left: 15px;
      font-size: 1.5rem;

      @media screen and (max-width: 468px) {
         font-size: 1rem;
      }
   }
`;

export const StudyAboardBannerContainer = styled.div<{ url: string }>`
   background-image: linear-gradient(#347bcc94 70%, #10223e90), url(${({ url }) => url && url});
   height: 450px;
   background-size: cover;
   margin-top: 48px;
   margin-bottom: 48px;

   .study-abroad-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      padding-left: 100px;
      line-height: 4.5rem;

      @media screen and (max-width: 468px) {
         padding-left: 30px;
         line-height: 3.5rem;
      }

      h3 {
         font-size: 40px;
         color: #fff;
         margin-bottom: 20px;

         @media screen and (max-width: 768px) {
            font-size: 3rem;
         }

         @media screen and (max-width: 468px) {
            font-size: 2.4rem;
         }
      }
   }
   @media screen and (max-width: 768px) {
      height: 250px;
      margin-bottom: 48px;
   }
`;

export const TestimonialCardsContainer = styled.div`
   margin: 0px auto;

   h3 {
      font-size: 3rem;
      margin-bottom: 40px;
      text-align: center;

      @media screen and (max-width: 468px) {
         font-size: 2.3rem;
         margin-bottom: 10px;
      }
   }
`;

export const TestimonialCardContainer = styled.div`
   padding: 50px 30px;
   margin-top: 30px;
   max-width: 400px;
   min-width: 80%;
   height: 280px;
   margin: 30px 40px;
   background-color: #264ffa;
   border-radius: 30px;
   color: #fff;
   font-size: 18px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   position: relative;
   .user-name {
      text-align: center;
      margin-top: 10px;
      border-bottom: 2px solid #fff;
      padding: 20px;
      text-transform: capitalize;
   }
   .quotes {
      width: 151px;
      height: 151px;
      position: absolute;
      top: -25%;
      left: 30%;
      @media screen and (max-width: 468px) {
         width: 100px;
         height: 100px;
         top: -15%;
         left: 40%;
      }
   }

   p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: fit-content;
      white-space: normal;
   }

   @media screen and (max-width: 468px) {
      line-height: 2.7rem;
      font-size: 1.5rem;
      border-radius: 30px;
      width: 100%;
      margin: 30px 0px;
   }

   img {
      width: 30px;
      border-radius: 50%;
      height: 30px;
   }

   .img-container {
      margin-top: 15px;
      display: flex;
      align-items: center;

      span {
         margin: 10px;
         -webkit-line-clamp: 1;
         -webkit-box-orient: vertical;
         overflow: hidden;
         text-overflow: ellipsis;
         height: fit-content;
         white-space: normal;
         display: -webkit-box;
         text-transform: capitalize;
      }
   }
`;

export const EduskoInTheNewsContainer = styled.div`
   margin-top: 50px;
   .news-image {
      object-fit: contain;
   }
`;

export const VideosDontLieContainer = styled.div`
   margin: 50px 30px;
   max-width: 100vw;
   margin: 40px 2em;

   .title {
      font-size: 15px;
      margin: 10px 0;
      font-weight: 500;
      text-transform: capitalize;
      text-align: center;
   }

   .video {
      border-radius: 5px;
      width: 50%;

      .play-icon {
         position: absolute;
      }

      .react-player video {
         position: relative;
         left: 0;
         top: 0;
         transform: none;
         min-height: 100%;
         min-width: 100%;
      }
   }
`;

export const NewBannerCards = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   @media screen and (max-width: 468px) {
      flex-direction: column;
      padding: 10px;
   }
`;

export const AdvertContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
`;

export const AdvertBox = styled.a<{ urlColor?: string }>`
   flex: 1;
   min-width: 300px;
   margin: 10px;
   background-color: #3498db;
   color: #fff;
   padding: 20px;
   border-radius: 8px;
   box-sizing: border-box;
   width: 382px;
   height: 321px;
   background: ${({ urlColor }) => urlColor};
   background-position: center;
   background-size: cover;

   color: #fff;
   font-weight: 600;
   transition: transform 300ms ease;
   cursor: pointer;

   display: flex;
   align-items: flex-end;

   &:hover {
      transform: translateY(10px);
   }

   h4 {
      padding-bottom: 8px;
      padding-left: 15px;
      font-size: 1.7rem;

      @media screen and (max-width: 468px) {
         font-size: 1.3rem;
      }
   }

   h6 {
      padding-bottom: 30px;
      padding-left: 15px;
      font-size: 1.5rem;

      @media screen and (max-width: 468px) {
         font-size: 1rem;
      }
   }

   @media (max-width: 767px) {
      flex: 100%;
      min-width: 100%;
      width: 100%;
      height: 250px;
   }
`;

export const NewBannerCard = styled.a<{ url?: string; w?: string; h?: string }>`
   min-height: ${({ h }) => h && h};
   width: ${({ w }) => w && w};
   background-image: url(${({ url }) => url && url});
   border-radius: 8px;
   background-position: center;
   background-size: cover;

   display: flex;
   align-items: flex-end;
   color: #fff;
   font-weight: 600;
   transition: transform 300ms ease;
   cursor: pointer;

   &:hover {
      transform: translateY(10px);
   }

   @media screen and (max-width: 468px) {
      min-height: 160px;
      width: 100%;
      margin-bottom: 12px;
   }

   h4 {
      padding-bottom: 8px;
      padding-left: 15px;
      font-size: 2rem;

      @media screen and (max-width: 468px) {
         font-size: 1.8rem;
      }
   }

   h6 {
      padding-bottom: 30px;
      padding-left: 15px;
      font-size: 1.5rem;

      @media screen and (max-width: 468px) {
         font-size: 1rem;
      }
   }
`;

export const SideBannerArea = styled.div`
   width: 68%;
   min-height: 662px;
   display: flex;
   flex-direction: column;
   .lower-cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 26px;
      @media screen and (max-width: 468px) {
         flex-direction: column;
         padding-top: 0px;
      }
   }
   @media screen and (max-width: 468px) {
      width: 100%;
      min-height: 0px;
   }
`;

export const UpdatedSchoolCards = styled.div`
   overflow-x: auto;
   margin-top: 15px;
   flex-wrap: nowrap;
   display: flex;
   justify-content: space-between;
   @media screen and (max-width: 768px) {
      gap: 15px;
   }
`;

export const NewSchoolCardContainer = styled.div`
   background: #fff;
   width: 100%;
   height: 460px;
   overflow: hidden;
   cursor: pointer;
   position: relative;

   border: 1px solid #cccccc;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
   margin: 0px 0px;
   border-radius: 7px;
   transition: transform 300ms ease-out;
   @media screen and (max-width: 768px) {
      width: 100%;
   }
`;

export const AbroadDivider = styled.div<{ pad?: string }>`
   width: 100%;
   height: 446px;
   display: flex;
   align-items: center;
   margin-top: 48px;
   .abroad-img {
      width: 53%;
      height: 446px;
      img {
         width: 100% !important;
         height: inherit;
      }
      @media screen and (max-width: 768px) {
         width: 100%;
         height: 196px;
      }
   }
   .text-container {
      width: 47%;
      height: 446px;
      background-color: #f1f6f7;
      /* padding: 70px 0px 100px 100px; */
      padding: ${({ pad }) => pad && pad};
      button {
         border-radius: 8px;
      }
      h3 {
         font-size: 32px;
         font-weight: 600;
         @media screen and (max-width: 768px) {
            padding-top: 39px;
            font-size: 24px;
         }
      }
      p {
         margin-top: 24px;
         font-size: 18px;
         margin-bottom: 40px;
         @media screen and (max-width: 768px) {
            font-size: 16px;
         }
      }
      button {
         width: 186px;
         @media screen and (max-width: 768px) {
            margin-bottom: 20px;
         }
      }
      @media screen and (max-width: 768px) {
         width: 100%;
         padding: 5px;
      }
   }
   @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 30px;
      margin-top: 28px;
   }
`;

export const ButtonArrow = styled.div`
   display: flex;
   align-items: center;
   gap: 12px;
   h5 {
      font-size: 16px;
      font-weight: 500;
   }
`;

export const NewArrow = styled(BsArrowRight)`
   font-size: 25px;
   color: #fff;
`;

export const AddSchoolUpdatedContainer = styled.div`
   background-color: ${({ theme }) => theme.colors?.primary};
   color: #fff;
   width: 100%;
   border-radius: 32px;
   display: flex;
   margin-top: 67px;
   .text-section {
      width: 50%;
      padding: 80px 0px 51px 114px;
      h3 {
         font-size: 32px;
         font-weight: 600;
         line-height: 40px;
      }
      p {
         margin-top: 22px;
         font-size: 20px;
      }
      .why-text {
         font-size: 16px;
         font-weight: 600;
         color: #fff;
         margin-top: 20px;
         text-decoration: underline;
         cursor: pointer;
      }
      button {
         padding: 10px 20px;
         outline: none;
         border: none;
         display: inline-block;
         margin-top: 20px;
         color: ${({ theme }) => theme.colors?.primary};
         background-color: #fff;
         font-weight: 600;
         border-radius: 5px;
      }
      @media screen and (max-width: 768px) {
         width: 100%;
         padding: 42px 0px 5px 20px;
         margin-top: 40px;
      }
   }
   .img-div {
      width: 50%;
      img {
         width: 100%;
         margin-bottom: -6px;
      }
      @media screen and (max-width: 768px) {
         width: 100%;
         margin-top: 30px;
      }
   }

   @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 40px;
   }
`;

export const UpdatedTestimonialContainer = styled.div`
   width: 100%;
   margin-top: 86px;

   @media screen and (max-width: 468px) {
   }
`;

export const TestimonialDivider = styled.div`
   width: 100%;
   .divider-one {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      h3 {
         font-weight: 500;
         font-size: 30px;
         line-height: 48px;
         @media screen and (max-width: 468px) {
            font-size: 24px;
         }
      }
      .slider-buttons {
         display: flex;
         gap: 24px;
         margin-top: 29px;
         .prev-button {
            cursor: pointer;
            img {
               height: 30px;
               width: 30px;
            }
         }
         .next-button {
            cursor: pointer;
            img {
               height: 30px;
               width: 30px;
            }
         }

         @media screen and (max-width: 468px) {
            .prev-button {
               img {
                  height: 20px;
                  width: 20px;
               }
            }
            .next-button {
               img {
                  height: 20px;
                  width: 20px;
               }
            }
         }
      }
      @media screen and (max-width: 468px) {
         width: 100%;
      }
   }
   .slider-footer {
      display: none;
      .mobile-slider-buttons {
         display: flex;
         margin-top: 38px;
         gap: 24px;
         .mobile-prev-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #3678e5;
            height: 32px;
            width: 32px;
            border-radius: 10px;
            cursor: pointer;
         }
         .mobile-next-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #3678e5;
            height: 32px;
            width: 32px;
            border-radius: 10px;
            cursor: pointer;
         }
         .disabled {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #a5adc0;
            height: 32px;
            width: 32px;
            border-radius: 10px;
            cursor: not-allowed;
         }
      }
      .analytics {
         display: flex;
         gap: 5px;
         h3 {
            font-size: 24px;
            font-weight: 400;
         }
         span {
            color: #6d7a98;
         }
         h6 {
            font-size: 14px;
            font-weight: 500;
            color: #6d7a98;
         }
      }
      @media screen and (max-width: 768px) {
         display: flex;
         width: 100%;
         justify-content: space-between;
         align-items: center;
         padding: 10px;
         background-color: #f1f6f7;
      }
   }
   .divider-two {
      width: 100%;
      @media screen and (max-width: 468px) {
         width: 100%;
      }
   }

   @media screen and (max-width: 468px) {
      flex-direction: column;
      margin-top: 40px;
   }
`;

export const NewTestimonialCardContainer = styled.div`
   padding: 24px;
   max-width: 536px;
   min-width: 100%;
   line-height: 24px;
   margin: 20px auto;
   // display: grid;
   background-color: #fff;
   border-radius: 4px;

   h4 {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
   }

   p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: fit-content;
      white-space: normal;
      font-size: 14px;
   }

   @media screen and (max-width: 468px) {
      line-height: 2.7rem;
      font-size: 1.5rem;
      border-radius: 5px;
      width: 100%;
   }
`;

export const MainAdvertContainer = styled.div`
   width: 100%;
   overflow-x: hidden;
   a {
      text-decoration: none;
   }
   .card-slider {
      margin-top: 48px;
      @media screen and (max-width: 768px) {
         width: 100%;
      }
   }
`;

export const SingleBlogAdvertContainer = styled.div`
   width: 100%;
   overflow-x: hidden;
   a {
      text-decoration: none;
   }
   .card-slider {
      margin-top: 10px;
      @media screen and (max-width: 768px) {
         width: 100%;
      }
   }
`;

export const BlogAdvertContainer = styled.div`
   width: 100%;
   overflow-x: hidden;
   a {
      text-decoration: none;
   }
   .card-slider {
      margin-top: 28px;
      @media screen and (max-width: 768px) {
         width: 100%;
      }
   }
`;

export const SecondMainAdvertContainer = styled.div`
   width: 100%;
   overflow-x: hidden;
   a {
      text-decoration: none;
   }
   .card-slider {
      margin-top: 48px;
      @media screen and (max-width: 768px) {
         width: 100%;
         margin-top: 120px;
         margin-bottom: 30px;
      }
   }
`;

export const SingleAvert = styled.div<{ url?: string }>`
   width: 580px;
   margin: 0 auto;
   height: 299px;
   background-image: url(${({ url }) => url && url});
   border-radius: 8px;
   background-position: center;
   background-size: cover;
   cursor: pointer;

   @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 12px;
      height: 180px;
   }
`;

export const SingleBlogAvert = styled.div<{ url?: string }>`
   width: 100%;
   height: 299px;
   background-image: url(${({ url }) => url && url});
   border-radius: 8px;
   background-position: center;
   background-size: cover;
   cursor: pointer;
   margin: auto;

   @media screen and (max-width: 768px) {
      margin-bottom: 12px;
      height: 160px;
   }
`;

export const SingleBlogPageMain = styled.div`
   width: 100%;
   height: auto;
   max-height: 600px;
   position: relative;
   background: #3678e5;
   margin-bottom: 400px;
   .center-text {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 85px;
      .text-section {
         width: 889px;
         .post-title {
            color: #fff;
            font-size: 56px;
            line-height: 70px;
         }
         .min-read {
            display: flex;
            gap: 7px;
            p {
               color: #fff;
               margin-top: 12px;
               font-size: 14px;
            }
            span {
               font-size: 40px;
               margin-right: 4px;
               color: #fff;
            }
         }
      }
   }
   @media screen and (max-width: 768px) {
      height: auto;
      max-height: 350px;
      margin-bottom: 200px;
      .center-text {
         padding-top: 30px;
         .text-section {
            width: 350px;
            .post-title {
               font-size: 24px;
               line-height: 32px;
            }
         }
      }
   }
`;

export const SingleBlogPage = styled.div<{ url?: string }>`
   width: 90%;
   height: 500px;
   background-image: url(${({ url }) => url && url});
   border-radius: 8px;
   background-position: center;
   background-size: cover;
   position: relative;
   left: 5%;
   top: 60px;
   @media screen and (max-width: 768px) {
      margin-bottom: 24px;
      height: 254px;
      top: 40px;
   }
`;

export const FeaturedSingleBlogPage = styled.div<{ url?: string }>`
   width: 100%;
   height: 500px;
   background-image: url(${({ url }) => url && url});
   border-radius: 8px;
   background-position: center;
   background-size: cover;
   margin-bottom: 56px;
   @media screen and (max-width: 768px) {
      margin-bottom: 24px;
   }
`;

export const ShareSection = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   .share-contents {
      width: 10%;
      display: flex;
      gap: 31%;
      align-items: center;
      p {
         font-size: 10px;
      }
      .social-button:focus {
         outline: none;
         box-shadow: none;
      }
   }
   @media screen and (max-width: 768px) {
      width: 50%;
      .share-contents {
         width: 50%;
         padding: 2%;
         .copy {
            z-index: 200000;
         }
         p {
            display: none;
         }
      }
   }
`;

export const ChoiceBannerCard = styled.a<{ url?: string; w?: string; h?: string }>`
   min-height: ${({ h }) => h && h};
   width: ${({ w }) => w && w};
   background-image: url(${({ url }) => url && url});
   border-radius: 8px;
   background-position: center;
   background-size: cover;
   cursor: pointer;

   display: flex;
   align-items: flex-end;
   color: #fff;
   font-weight: 600;
   transition: transform 300ms ease;

   &:hover {
      transform: translateY(10px);
   }

   @media screen and (max-width: 468px) {
      min-height: 200px;
      width: 100%;
      margin-bottom: 12px;
   }

   h4 {
      padding-left: 33px;
      font-size: 20px;

      @media screen and (max-width: 468px) {
         font-size: 17px;
      }
   }

   h6 {
      padding-bottom: 10px;
      padding-left: 33px;
      font-size: 15px;
      width: 314px;

      @media screen and (max-width: 468px) {
         font-size: 11px;
      }
   }
   .button-area {
      padding-left: 33px;
      padding-bottom: 30px;
      button {
         width: 130px;
         height: 48px;
         border-radius: 8px;
      }
      @media screen and (max-width: 468px) {
         padding-bottom: 20px;
      }
   }
`;

export const ChoiceSideBannerArea = styled.div`
   width: 58%;
   min-height: 504px;
   display: flex;
   flex-direction: column;
   .lower-cards {
      padding-top: 32px;
      @media screen and (max-width: 468px) {
         padding-top: 0px;
      }
   }
   @media screen and (max-width: 468px) {
      width: 100%;
      min-height: 0px;
   }
`;

export const ChoiceArrow = styled(BsArrowRight)`
   font-size: 25px;
   color: #3578e5;
`;

export const EdcommerceMainAdvertContainer = styled.div`
   width: 100%;
   a {
      text-decoration: none;
   }
   .card-slider {
      @media screen and (max-width: 768px) {
         width: 100% !important;
      }
   }
`;

export const SingleEdcommerceAvert = styled.div<{ url?: string }>`
   width: 100%;
   height: 370px;
   background-image: url(${({ url }) => url && url});
   background-position: center;
   background-size: 100% auto;
   cursor: pointer;
   background-repeat: no-repeat !important;

   @media screen and (max-width: 768px) {
      background-size: 100% auto;
      background-repeat: no-repeat;
      width: 100%;
      height: 133px !important;
   }
`;

export const NewsContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
`;

export const SingleNews = styled.div`
   flex: 1;
   min-width: 200px;
   margin: 10px;
   padding: 20px 10px;
   background: #92a7fc;
   border-radius: 30px;
   box-sizing: border-box;
   display: flex;
   align-items: center;
   justify-content: center;

   @media (max-width: 767px) {
      flex: 100%;
   }
`;
