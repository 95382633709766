import React from "react";
// import Image from "next/image";
import { BrandImage2 } from "components/brand-image";
import * as S from "styles/index/new-index.style";
// edusko-white-logo.png

const navigation = {
   mainNav: {
      Company: [
         { name: "About Us", href: "/about-us" },
         { name: "FAQs", href: "/faq" },
         { name: "Privacy Policy", href: "/privacy-policy" },
         { name: "Terms of Use", href: "/t&c" },
      ],
      "Other Links": [
         { name: "Summer Camps", href: "/summer-camp" },
         { name: "Edusko Board", href: "/advisory" },
         { name: "List Your School", href: "/signup?type=sch_admin" },
      ],
      "Education Finance": [
         { name: "Affordable Tuition Finance", href: "/education-finance#loans" },
         { name: "School Finance", href: "/education-finance#loans" },
         { name: "Endowment Plan", href: "https://edgo.co" },
      ],
   },
   social: [
      // {
      //    name: "LinkedIn",
      //    href: "https://ng.linkedin.com/in/edusko-africa-aa9b16103",
      //    Icon: () => (
      //       <svg
      //          width="24"
      //          height="24"
      //          viewBox="0 0 24 24"
      //          fill="none"
      //          xmlns="http://www.w3.org/2000/svg"
      //       >
      //          <path
      //             d="M12.4041 11.0835L13.3496 11.3067C13.3511 11.3075 13.3511 11.3075 13.3511 11.3075L13.3508 11.308L13.3505 11.3087L13.3499 11.3098L13.3525 11.3049C13.356 11.2986 13.3624 11.2871 13.372 11.2711C13.3911 11.239 13.4226 11.1892 13.4675 11.1276C13.5574 11.0038 13.6989 10.835 13.899 10.6654C14.2936 10.331 14.9259 9.98439 15.879 9.98439C16.7748 9.98439 17.5195 10.2053 18.0392 10.6917C18.5523 11.172 18.9272 11.9879 18.9272 13.3543V18.9988H16.8739V14.0988C16.8739 13.1561 16.6205 12.5073 16.1464 12.115C15.6897 11.7372 15.1348 11.6908 14.7569 11.6908C13.8533 11.6908 13.2627 12.0986 12.9295 12.6773C12.6207 13.2138 12.5572 13.8523 12.5572 14.323V18.9988H10.4577V10.2337H12.4041V11.0835ZM7.82952 6.64206C7.82952 7.34776 7.25743 7.91985 6.55172 7.91985C5.84602 7.91985 5.27393 7.34776 5.27393 6.64206C5.27393 5.93635 5.84602 5.36426 6.55172 5.36426C7.25743 5.36426 7.82952 5.93635 7.82952 6.64206ZM5.50197 10.2337H7.60148V18.9988H5.50197V10.2337Z"
      //             fill="#3578E5"
      //             stroke="white"
      //          />
      //       </svg>
      //    ),
      // },
      {
         name: "Instagram",
         href: "https://www.instagram.com/eduskoafrica/",
         Icon: () => (
            <svg
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
               <path
                  d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
               <path
                  d="M17.6361 7H17.6477"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
            </svg>
         ),
      },
      {
         name: "Twitter",
         href: "https://twitter.com/EduskoAfrica",
         Icon: () => (
            <svg
               width="24"
               height="21"
               viewBox="0 0 24 21"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M23 1.9998C22.0424 2.67528 20.9821 3.19191 19.86 3.5298C19.2577 2.83731 18.4573 2.34649 17.567 2.12373C16.6767 1.90096 15.7395 1.957 14.8821 2.28426C14.0247 2.61151 13.2884 3.1942 12.773 3.95352C12.2575 4.71283 11.9877 5.61214 12 6.5298V7.5298C10.2426 7.57537 8.50127 7.18561 6.93101 6.39525C5.36074 5.60488 4.01032 4.43844 3 2.9998C3 2.9998 -1 11.9998 8 15.9998C5.94053 17.3978 3.48716 18.0987 1 17.9998C10 22.9998 21 17.9998 21 6.4998C20.9991 6.22126 20.9723 5.9434 20.92 5.6698C21.9406 4.6633 22.6608 3.39251 23 1.9998V1.9998Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
            </svg>
         ),
      },
      {
         name: "Facebook",
         href: "https://www.facebook.com/eduskoafrica",
         Icon: () => (
            <svg
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M14 9.3V12.2H16.6C16.8 12.2 16.9 12.4 16.9 12.6L16.5 14.5C16.5 14.6 16.3 14.7 16.2 14.7H14V22H11V14.8H9.3C9.1 14.8 9 14.7 9 14.5V12.6C9 12.4 9.1 12.3 9.3 12.3H11V9C11 7.3 12.3 6 14 6H16.7C16.9 6 17 6.1 17 6.3V8.7C17 8.9 16.9 9 16.7 9H14.3C14.1 9 14 9.1 14 9.3Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
               />
               <path
                  d="M15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
            </svg>
         ),
      },
      {
         name: "YouTube",
         href: "https://www.youtube.com/channel/UCkO2k31pCdk7KVACIZFr-oQ",
         Icon: () => (
            <svg
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M22.5401 6.42C22.4213 5.94541 22.1794 5.51057 21.8387 5.15941C21.4981 4.80824 21.0708 4.55318 20.6001 4.42C18.8801 4 12.0001 4 12.0001 4C12.0001 4 5.12008 4 3.40008 4.46C2.92933 4.59318 2.50206 4.84824 2.16143 5.19941C1.8208 5.55057 1.57887 5.98541 1.46008 6.46C1.1453 8.20556 0.991319 9.97631 1.00008 11.75C0.988863 13.537 1.14285 15.3213 1.46008 17.08C1.59104 17.5398 1.83839 17.9581 2.17823 18.2945C2.51806 18.6308 2.9389 18.8738 3.40008 19C5.12008 19.46 12.0001 19.46 12.0001 19.46C12.0001 19.46 18.8801 19.46 20.6001 19C21.0708 18.8668 21.4981 18.6118 21.8387 18.2606C22.1794 17.9094 22.4213 17.4746 22.5401 17C22.8524 15.2676 23.0064 13.5103 23.0001 11.75C23.0113 9.96295 22.8573 8.1787 22.5401 6.42V6.42Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
               <path
                  d="M9.75 15.02L15.5 11.75L9.75 8.47998V15.02Z"
                  stroke="white"
                  strokeOpacity="0.976471"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
            </svg>
         ),
      },
   ],
};

const Footer: React.FC = () => {
   return (
      <S.FooterContainer>
         <S.InnerFooterContainer>
            <div className="first-section">
               <S.LogoWrapper>
                  <BrandImage2 />
                  <S.ExtraSection>
                     <S.NavLink href={"mailto:info@edusko.com"}>info@edusko.com</S.NavLink>
                     <S.NavLink href={"tel:+2347052704253"}>+234 705 2704 253</S.NavLink>
                  </S.ExtraSection>
               </S.LogoWrapper>

               <S.NavigationContainer>
                  {Object.entries(navigation.mainNav).map(([section, links]) => (
                     <S.NavigationSection key={section}>
                        <S.NavTitle>{section}</S.NavTitle>
                        {links.map((link) => (
                           <S.NavLink key={link.name} href={link.href}>
                              {link.name}
                           </S.NavLink>
                        ))}
                     </S.NavigationSection>
                  ))}
               </S.NavigationContainer>
               <S.DownloadContentFooter>
                  <p>Download the Edusko App</p>
                  <h1>
                     Need More? Download <br /> Edusko App Now!
                  </h1>
                  <S.DownloadLinksContainer>
                     <a href="#">
                        <AppleSVG />
                        <span>Get on iPhone</span>
                     </a>
                     <a
                        href="https://play.google.com/store/apps/details?id=com.edusko.companion"
                        // target="_blank"
                     >
                        <>
                           <AndroidSVG />
                           <span>Get on Android</span>
                        </>
                     </a>
                  </S.DownloadLinksContainer>
               </S.DownloadContentFooter>
            </div>

            <S.SocialCopyrightContainer>
               <S.SocialIcons>
                  <S.SocialIconLink
                     href={"https://ng.linkedin.com/in/edusko-africa-aa9b16103"}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M12.4041 11.0835L13.3496 11.3067C13.3511 11.3075 13.3511 11.3075 13.3511 11.3075L13.3508 11.308L13.3505 11.3087L13.3499 11.3098L13.3525 11.3049C13.356 11.2986 13.3624 11.2871 13.372 11.2711C13.3911 11.239 13.4226 11.1892 13.4675 11.1276C13.5574 11.0038 13.6989 10.835 13.899 10.6654C14.2936 10.331 14.9259 9.98439 15.879 9.98439C16.7748 9.98439 17.5195 10.2053 18.0392 10.6917C18.5523 11.172 18.9272 11.9879 18.9272 13.3543V18.9988H16.8739V14.0988C16.8739 13.1561 16.6205 12.5073 16.1464 12.115C15.6897 11.7372 15.1348 11.6908 14.7569 11.6908C13.8533 11.6908 13.2627 12.0986 12.9295 12.6773C12.6207 13.2138 12.5572 13.8523 12.5572 14.323V18.9988H10.4577V10.2337H12.4041V11.0835ZM7.82952 6.64206C7.82952 7.34776 7.25743 7.91985 6.55172 7.91985C5.84602 7.91985 5.27393 7.34776 5.27393 6.64206C5.27393 5.93635 5.84602 5.36426 6.55172 5.36426C7.25743 5.36426 7.82952 5.93635 7.82952 6.64206ZM5.50197 10.2337H7.60148V18.9988H5.50197V10.2337Z"
                           fill="#3578E5"
                           stroke="white"
                        />
                     </svg>
                     <span className="">LinkedIn</span>
                  </S.SocialIconLink>

                  {navigation.social.map(({ name, href, Icon }) => (
                     <S.SocialIconLink
                        key={name}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        {<Icon />}
                        <span>{name}</span>
                     </S.SocialIconLink>
                  ))}
               </S.SocialIcons>
               <S.CopyrightSection>
                  <p>© {new Date().getFullYear()} EDUSKO - All Rights Reserved.</p>
               </S.CopyrightSection>
            </S.SocialCopyrightContainer>
         </S.InnerFooterContainer>
      </S.FooterContainer>
   );
};

const AppleSVG = () => (
   <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M13.0492 17.28C12.0692 18.23 10.9992 18.08 9.96919 17.63C8.87919 17.17 7.87919 17.15 6.72919 17.63C5.28919 18.25 4.52919 18.07 3.66919 17.28C-1.21081 12.25 -0.490812 4.59 5.04919 4.31C6.39919 4.38 7.33919 5.05 8.12919 5.11C9.30919 4.87 10.4392 4.18 11.6992 4.27C13.2092 4.39 14.3492 4.99 15.0992 6.07C11.9792 7.94 12.7192 12.05 15.5792 13.2C15.0092 14.7 14.2692 16.19 13.0392 17.29L13.0492 17.28ZM8.02919 4.25C7.87919 2.02 9.68919 0.18 11.7692 0C12.0592 2.58 9.42919 4.5 8.02919 4.25Z"
         fill="white"
      />
   </svg>
);

const AndroidSVG = () => (
   <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_885_13077)">
         <path
            d="M10.1387 11.4414L0.0898438 21.9696C0.202692 22.3695 0.405483 22.7383 0.682716 23.0479C0.959948 23.3574 1.30428 23.5995 1.6894 23.7555C2.07451 23.9116 2.49021 23.9775 2.90471 23.9483C3.31922 23.9191 3.72155 23.7955 4.08097 23.5869L15.3879 17.1473L10.1387 11.4414Z"
            fill="#EA4335"
         />
         <path
            d="M20.2994 9.65218L15.41 6.85156L9.90625 11.6812L15.4324 17.1326L20.2846 14.362C20.7144 14.1369 21.0745 13.7984 21.3257 13.3833C21.5769 12.9682 21.7097 12.4922 21.7097 12.007C21.7097 11.5218 21.5769 11.0459 21.3257 10.6307C21.0745 10.2156 20.7144 9.87718 20.2846 9.6521L20.2994 9.65218Z"
            fill="#FBBC04"
         />
         <path
            d="M0.0899202 1.99219C0.0292158 2.21675 -0.00101976 2.44845 2.62359e-05 2.68106V21.2812C0.000625649 21.5138 0.0308332 21.7453 0.0899202 21.9702L10.4833 11.7116L0.0899202 1.99219Z"
            fill="#4285F4"
         />
         <path
            d="M10.2136 11.9808L15.4103 6.85154L4.11845 0.381885C3.6936 0.133083 3.2104 0.00131666 2.71806 5.20116e-06C1.49626 -0.00236936 0.422112 0.808542 0.0898438 1.98437L10.2136 11.9808Z"
            fill="#34A853"
         />
      </g>
      <defs>
         <clipPath id="clip0_885_13077">
            <rect width="21.7102" height="24" fill="white" />
         </clipPath>
      </defs>
   </svg>
);
export default Footer;
