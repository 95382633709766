import styled, { css } from "styled-components";
import {
   color,
   space,
   layout,
   SpaceProps,
   LayoutProps,
   typography,
   TypographyProps,
   display,
   DisplayProps,
   justifyContent,
   JustifyContentProps,
   alignItems,
   AlignItemsProps,
   BackgroundColorProps,
   border,
   BorderProps,
   borderRadius,
   BorderRadiusProps,
} from "styled-system";
// backgroundColor,
import { responsive } from "theme/reponsive";

type Props = SpaceProps &
   LayoutProps &
   TypographyProps &
   DisplayProps &
   AlignItemsProps &
   JustifyContentProps &
   BackgroundColorProps &
   BorderProps &
   BorderRadiusProps;

export const Heading = styled.p<Props>`
   ${space}
   ${layout}
   ${typography}
   ${color}
   ${display}
   ${alignItems}
   ${justifyContent}
`;

/* ${backgroundColor} */
export const div = styled.div<Props>`
   ${space}
   ${layout}
   ${typography}
   ${color}
   ${display}
   ${alignItems}
   ${justifyContent}
   ${border}
   ${borderRadius}
`;

export const HeadingOne = styled(Heading)`
   font-weight: 500;
   font-size: 21px;
   color: ${({ theme }) => theme.colors?.primary};
`;

export const SummerHeading = styled(Heading)`
   font-weight: 500;
   font-size: 30px;
   margin-top: 10px;
   margin-bottom: 30px;
   padding: 2rem;
`;

export const Span = styled(Heading)`
   display: inline;
`;

export const Div = styled(div)<{ flex?: boolean }>`
   /* margin-bottom: 2rem; */
   a {
      color: #3183ff;
      font-weight: 500;
   }
   /* ${responsive("$small")`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   `} */
   ${({ flex }) =>
      flex &&
      css`
         display: flex;
         justify-content: center;
         align-items: center;
      `}
`;

export const TextHeadingTwo = styled(Heading)`
   font-size: 40px;
   font-weight: 700;
   letter-spacing: -0.9px;
   color: #fff;
   width: 100%;
   line-height: 1.8;
   text-transform: capitalize;
   ${responsive("$small")`
      font-size: 3rem;
      max-width: 280px
   `}
`;

export const FAQHeading = styled(Heading)`
   width: 100%;
   text-align: center;
   font-size: 30px;
   font-weight: 700;
   letter-spacing: -0.9px;
   color: #fff;
   width: 100%;
   line-height: 1.8;
   padding: 20px;
   text-transform: capitalize;
   ${responsive("$small")`
      font-size: 20px;
      padding:10px;
      margin-top:20px;
   `}
`;

export const TextHeadingThree = styled(Heading)`
   font-size: 30px;
   font-weight: 700;
   letter-spacing: -0.9px;
   color: #fff;
   width: 100%;
   line-height: 1.8;
   text-transform: capitalize;
   ${responsive("$small")`
      font-size: 2rem;
      max-width: 280px
   `}
`;

export const TextHeadingOne = styled.h1<{ color: string }>`
   font-size: 7rem;
   font-weight: 700;
   letter-spacing: -0.9px;
   color: ${({ color }) => color ?? "#fff"};
   text-align: left;
   width: 100%;
   line-height: 1.1;
   text-transform: capitalize;
   ${responsive("$xsmall")`
   font-size: 2.5rem;
   `}
`;

export const TextHeadingMain = styled.h1`
   font-size: 32px !important;
   font-weight: 500;
   letter-spacing: -0.9px;
   color: #fff;
   text-align: left;
   max-width: 80%;
   line-height: 1.8;
   position: relative;
   text-transform: capitalize;
   ${responsive("$xsmall")`
      font-size: 2rem !important;
      line-height: 1;
      
      div {
         width: 320px;
      }
   `}
`;

export const TextBrief = styled(Heading)`
   font-size: 1.65rem;
   color: #fff;
   max-width: 50%;
   line-height: 3rem;
   font-weight: 400;
   &.black {
      color: #666;
      max-width: 70%;
      margin-bottom: 30px;
      ${responsive("$xsmall")`
      max-width: 300px;
   `}
   }
   ${responsive("$small")`
      margin-top: 10px;
      max-width: 300px;
   `}
`;

export const TextBriefTwo = styled(Heading)`
   font-size: 1.65rem;
   color: #fff;
   line-height: 3rem;
   max-width: 60%;
   color: #fff;
   ${responsive("$small")`
      max-width: 90%;
      margin-top: 10px;
   `}
`;

export const PageTitle = styled.div<{ flex: boolean }>`
   font-size: 18px;
   margin: 0 auto;
   text-align: left;
   margin: 20px 0 20px 0;
   border-radius: 4px;
   .flex-title {
      display: flex;
      align-items: flex-end;
      gap: 8px;
      span {
         font-weight: 500;
         text-transform: capitalize;
         font-weight: 500;
         font-size: 24px;
         line-height: 30px;
         &:nth-child(2) {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
         }
      }
   }
   .vendor-title {
      h4 {
         font-weight: 500;
         text-transform: capitalize;
         font-weight: 500;
         font-size: 24px;
         line-height: 30px;
      }
      p {
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         margin-top: 8px;
      }
   }
   span {
      font-weight: 500;
      text-transform: capitalize;
   }
   ${({ flex }) =>
      flex &&
      css`
         display: flex;
         justify-content: space-between;
         align-items: center;
      `}
`;

export const PageName = styled.p`
   font-weight: 500;
   font-size: 19px;
   margin: 20px 0 20px 0;
`;

export const FinanceTitle = styled.p`
   font-weight: 500;
   font-size: 19px;
   margin: 20px 0 1px 0;
   text-transform: capitalize;
`;

export const PageDescription = styled.p`
   font-size: 14px;
   color: #999999;
   margin: 0 0 27px 0;
`;

export const MainHeading = styled(Heading)`
   font-weight: 700;
   font-size: 40px;
   color: #444;
   line-height: 51px;
   @media screen and (max-width: 990px) {
      font-size: 24px;
      line-height: 32px;
   }
`;

export const Div2 = styled(div)<{ flex?: boolean }>`
   margin-bottom: 5rem;
   margin-top: 2rem;
   a {
      color: #1c1c39;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      text-decoration: none;
   }
   /* ${responsive("$small")`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   `} */
   ${({ flex }) =>
      flex &&
      css`
         display: flex;
         justify-content: center;
         align-items: center;
      `}
`;
