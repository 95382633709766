/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react";
import { useRouter } from "next/router";
import ReactFlagsSelect from "react-flags-select";
import { FaTimes, FaBars } from "react-icons/fa";
import { BrandImage } from "components/brand-image";
import { Button } from "components/button";
import UserModal from "components/user-modal/user-modal";
// import { LandingPageContainer } from "styles/index/new-index.style";
import { Div } from "styles/typography";
import { getSession } from "utils/auth";
import { isClient } from "utils/generic-utils";
import { MobileNav } from "./mobile-nav";
import {
   NavbarContainer,
   OtherLinks,
   MenuIcon,
   NavBarLinks,
   BackDrop,
   RadiusButton,
   CountryLinks,
   SubscriptionTag,
   NavLink,
   NavLinks,
   InnerNavbarContainer,
} from "./navbar.style";

const Navbar = () => {
   const router = useRouter();
   const initialCountry = (path) => {
      switch (true) {
         case path.pathname === "/":
            return "NG";
         case path.pathname === "/ghana":
            return "GH";
         case path.pathname === "/kenya":
            return "KE";

         default:
            return;
      }
   };
   const { user, inSession } = getSession();
   const [select, setSelect] = useState<any>(initialCountry(router));

   const [navIconClicked, setNavIconClicked] = useState<boolean>(false);
   const [userProfileMenuClicked, setUserProfileMenuClicked] = useState<boolean>(false);

   const handleClick = () => {
      setNavIconClicked(!navIconClicked);
      if (!navIconClicked) {
         document.body.style.overflow = "hidden";
         setUserProfileMenuClicked(false);
      } else {
         document.body.style.overflow = "auto";
      }
   };

   const onSelect = (code) => {
      setSelect(code);
      switch (true) {
         case code === "NG":
            return router.push("/");
         case code === "GH":
            return router.push("/ghana");
         case code === "KE":
            return router.push("/kenya");

         default:
            return;
      }
   };

   function handleSchoolRegistration() {
      if (inSession) {
         router.push("/admin/school/add");
      } else {
         router.push({
            pathname: "/signup",
            query: {
               type: "sch_admin",
            },
         });
      }
   }

   const isLandingPage = isClient && router.pathname === "/";
   const isAdmin =
      user?.type === "sch_admin" || user?.type === "super_admin" || user?.type === "loan_manager";
   const handleAdminRoute = () => {
      return user?.type === "loan_manager"
         ? router.push("/admin/finance/dashboard")
         : user?.type === "sch_admin"
         ? router.push("/admin/school/dashboard")
         : router.push("/admin/dashboard");
   };

   function showActionButton() {
      if (user?.type === "sch_admin") {
         return (
            <Button variant="new_primary" size="small" onClick={handleSchoolRegistration}>
               Add Your School
            </Button>
         );
      } else if (inSession && isAdmin) {
         return (
            <Button variant="new_primary" size="small" onClick={handleAdminRoute}>
               My Dashboard
            </Button>
         );
      } else if (inSession && user?.type === "study_abroad_partner") {
         return (
            <Button
               variant="new_primary"
               size="small"
               onClick={() => router.push("/admin/study-abroad-partner/settings/user-account")}
            >
               My Account
            </Button>
         );
      } else if (inSession) {
         return (
            <Button variant="new_primary" onClick={() => router.push("/account")}>
               My Account
            </Button>
         );
      } else {
         return (
            <RadiusButton>
               <Button variant="new_primary" onClick={handleSchoolRegistration}>
                  List Your School
               </Button>
            </RadiusButton>
         );
      }
   }

   const mapPathToIcon = {
      premium: "/images/premium-sub.png",
      enhanced: "/images/enhanced-sub.png",
   };

   return (
      <>
         <NavbarContainer className="isSticky" isLandingPage={isLandingPage}>
            <InnerNavbarContainer>
               <div>
                  <BrandImage />
               </div>
               <MenuIcon onClick={handleClick}>
                  {navIconClicked ? (
                     <FaTimes style={{ zIndex: "2000" }} color="#3678E5" />
                  ) : (
                     <FaBars style={{ zIndex: "2000" }} color="#3678E5" />
                  )}
               </MenuIcon>

               <NavBarLinks menuActive={navIconClicked}>
                  <NavLinks>
                     <NavLink onClick={() => router.push(`/compare-schools`)}>
                        Compare Schools
                     </NavLink>
                     <NavLink onClick={() => router.push(`/study-abroad`)}>Study Abroad</NavLink>
                     <NavLink onClick={() => router.push(`/education-finance`)}>
                        Education Finance
                     </NavLink>
                     <NavLink onClick={() => router.push(`/blog`)}>Blog</NavLink>
                  </NavLinks>
                  <OtherLinks menuActive={navIconClicked}>
                     {/* {user?.type !== "parent" && (
                     <BecomeAVendor onClick={() => router.push(`/edcommerce/signup`)}>
                        Become a vendor
                     </BecomeAVendor>
                  )} */}
                     {showActionButton()}

                     <Div ml={["0px", "0px", "0px", "20px"]} mt={["20px", "20px", "20px", "0px"]}>
                        {!inSession ? (
                           <RadiusButton>
                              <Button
                                 variant="new_outline"
                                 // size="small"
                                 onClick={() => router.push("/signin")}
                              >
                                 Log In
                              </Button>
                           </RadiusButton>
                        ) : (
                           <div className="dropdown">
                              <p className="dropdown-text">
                                 {`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName
                                    ?.charAt(0)
                                    ?.toUpperCase()}`}
                              </p>
                              <SubscriptionTag subType={user.studyAbroadSubscriptionType}>
                                 {user.studyAbroadSubscriptionType}
                              </SubscriptionTag>
                              {user.studyAbroadSubscriptionType != "basic" && (
                                 <div className="subscription-icon">
                                    <img
                                       src={mapPathToIcon[user.studyAbroadSubscriptionType]}
                                       alt="icon"
                                    />
                                 </div>
                              )}

                              <div className="dropdown-menu">
                                 <UserModal />
                              </div>
                           </div>
                        )}
                     </Div>
                  </OtherLinks>
                  <CountryLinks>
                     <ReactFlagsSelect
                        selected={select}
                        onSelect={onSelect}
                        countries={["NG", "GH", "KE"]}
                        showSelectedLabel={false}
                        fullWidth={false}
                        showOptionLabel={false}
                        selectedSize={25}
                        optionsSize={25}
                        placeholder="x"
                     />
                  </CountryLinks>

                  <div className="bottom-brand">
                     <BrandImage />
                  </div>
               </NavBarLinks>
            </InnerNavbarContainer>
         </NavbarContainer>
         <MobileNav
            menuActive={navIconClicked}
            setNavIconClicked={setNavIconClicked}
            setUserProfileMenuClicked={setUserProfileMenuClicked}
            userProfileMenuClicked={userProfileMenuClicked}
         />
         <BackDrop menuActive={navIconClicked} onClick={handleClick} />
      </>
   );
};

export { Navbar };
