import router from "next/router";
import {
   BrandImageStyledContainer,
   BrandImageStyledComponent,
   MarketBrandImageStyledContainer,
} from "./brand-image.style";

export const BrandImage = () => {
   return (
      <BrandImageStyledContainer onClick={() => router.push("/")}>
         <BrandImageStyledComponent src="/landing-page/edusko_weblogo.png" alt="icon" />
         {/* <BrandImageStyledComponent src="/images/edu_new_logo.png" alt="icon" /> */}
      </BrandImageStyledContainer>
   );
};

export const BrandImageNew = () => {
   return (
      <BrandImageStyledContainer onClick={() => router.push("/")}>
         <BrandImageStyledComponent src="/images/edu_new_logo.png" alt="icon" />
      </BrandImageStyledContainer>
   );
};

export const BrandImage2 = () => {
   return (
      <BrandImageStyledContainer onClick={() => router.push("/")}>
         <BrandImageStyledComponent src="/images/edusko-white.png" alt="icon" />
      </BrandImageStyledContainer>
   );
};

export const MarketBrandImage = () => {
   return (
      <MarketBrandImageStyledContainer onClick={() => router.push("/edcommerce")}>
         <BrandImageStyledComponent src="/images/marketlogo.png" alt="icon" />
      </MarketBrandImageStyledContainer>
   );
};
