import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { responsive } from "theme/reponsive";
import { IInputProps } from "./i-input";

export const Div = styled.div<{ fullWidth?: boolean | undefined }>`
   ${({ fullWidth }) => fullWidth && "width: 100%;"}
   position: relative;
   display: inline-block;
   width: 100%;
`;

export const Label = styled.label<{ whiteSpace?: boolean | undefined }>`
   pointer-events: none;
   color: ${({ theme }) => theme.colors?.grey};
   white-space: ${({ whiteSpace }) => (whiteSpace ? "none" : "nowrap")};
   margin-bottom: 0.3rem;

   font-size: 1.4rem;
   display: block;
   font-weight: 400;
   max-width: ${({ whiteSpace }) => (whiteSpace ? "100%" : "350px")};
   color: ${({ theme }) => theme.colors?.black};
   ${Div}:focus-within & {
      color: ${({ theme }) => theme.colors?.primary};
   }
`;

export const HiddenLabel = styled.label<{ whiteSpace?: boolean | undefined }>`
   pointer-events: none;
   white-space: ${({ whiteSpace }) => (whiteSpace ? "none" : "nowrap")};
   margin-bottom: 0.3rem;
   font-size: 1.4rem;
   display: block;
   font-weight: 400;
   max-width: ${({ whiteSpace }) => (whiteSpace ? "100%" : "350px")};
   color: ${({ theme }) => theme.colors?.black};
   opacity: 0; /* Initially hide the label */

   /* Add a hover effect on the parent element to make the label visible */
   ${Div}:hover & {
      opacity: 1; /* Make the label visible on hover */
      transition: opacity 0.2s ease-in-out; /* Add a smooth transition effect */
   }

   /* Style for when the parent element is focused within */
   ${Div}:focus-within & {
      color: ${({ theme }) => theme.colors?.primary};
   }
`;

export const SecondaryLabel = styled.label<{ whiteSpace?: boolean | undefined }>`
   pointer-events: none;
   white-space: ${({ whiteSpace }) => (whiteSpace ? "none" : "nowrap")};
   margin-bottom: 0.3rem;

   font-size: 1.6rem;
   line-height: 2rem;
   display: block;
   font-weight: 400;
   max-width: ${({ whiteSpace }) => (whiteSpace ? "100%" : "350px")};
   color: ${({ theme }) => theme.colors?.black};

   ${Div}:focus-within & {
      color: ${({ theme }) => theme.colors?.primary};
   }
`;

export const AdornmentWrapperDiv = styled.div`
   display: flex;
   align-items: center;
   white-space: nowrap;
   position: absolute;
   padding-left: 10px;
   margin-right: 10px;
   cursor: pointer;

   &.end {
      right: 0px;
      top: 30%;
      cursor: pointer;
   }
`;

export const InputFieldWrapperDiv = styled.div`
   position: relative;
   display: flex;
   align-items: center;
   width: 100%;
   z-index: 100;

   ::before,
   ::after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
   }

   ::before {
      /* border-left: linear-gradient(270deg, #00d2ff -8.34%, #7e51ff 50.93%, #fa4a84 106.85%); */
      border-left: 1px solid ${({ theme }) => theme && theme.colors?.grey02};
   }
`;

export const InputField = styled.input<IInputProps>`
   width: 100%;
   border: none;
   outline: none;
   font-size: 1.5rem;
   appearance: none;
   border-radius: 0;
   padding: 0 ${({ startAdornment }) => (startAdornment ? "3.8rem" : "1rem")};
   box-sizing: border-box;
   border-radius: 0px;
   border: 1px solid rgb(243 242 242);
   background-color: #fbfbfb;
   height: 4.8rem;

   &:focus {
      border-left: 1px solid grey;
   }

   &::-ms-clear {
      display: none;
   }

   &::-ms-expand {
      display: none;
   }

   &:-moz-ui-invalid {
      box-shadow: none;
   }

   &::placeholder {
      font-weight: 400;
      opacity: 0.4;
   }

   &:-webkit-autofill,
   &:-webkit-autofill:hover,
   &:-webkit-autofill:focus,
   &:-webkit-autofill:active {
      transition: background-color 5000s;
      -webkit-text-fill-color: #000 !important;
   }
   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   &:disabled {
      background-color: hsl(0, 0%, 95%);
   }
`;

export const SecondaryInputField = styled.input<IInputProps>`
   width: 100%;
   border: none;
   outline: none;
   font-size: 1.4rem;
   appearance: none;
   border-radius: 0;
   padding: 0 1.4rem;
   box-sizing: border-box;
   border-radius: 5px;
   background-color: #f1f1f1;
   border: 1px solid #fafafa;
   height: 4.2rem;
   color: #666666;

   &:focus {
      border: ${({ theme }) => theme && `2px solid ${theme.colors?.primary}`};
   }

   &::-ms-clear {
      display: none;
   }

   &::-ms-expand {
      display: none;
   }

   &:-moz-ui-invalid {
      box-shadow: none;
   }

   &::placeholder {
      font-weight: 400;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
   }

   &:-webkit-autofill,
   &:-webkit-autofill:hover,
   &:-webkit-autofill:focus,
   &:-webkit-autofill:active {
      transition: background-color 5000s;
      -webkit-text-fill-color: #000 !important;
   }

   &:disabled {
      background-color: hsl(0, 0%, 95%);
   }
`;

export const ErrorP = styled(motion.p)<{ easyFlow?: boolean }>`
   margin: 0.1rem 0 0 0;
   color: ${({ theme }) => theme.colors?.redError};
   font-size: 1.4rem;
   letter-spacing: -0.01rem;
   font-weight: 400;

   ${({ easyFlow }) =>
      easyFlow &&
      css`
         margin: 0;
         position: absolute;
         left: 65px;
         bottom: -21px;
         font-size: 11.5px;
      `}
`;

export const PasswordControlButton = styled.button`
   background: none;
   border: none;
   width: 30px;
   overflow: hidden;

   .eye-icon {
      outline: none;
   }
`;

export const SummerInputField = styled.input<IInputProps>`
   width: 100%;
   border: none;
   outline: none;
   font-size: 1.4rem;
   appearance: none;
   border-radius: 0;
   padding: 0 1.4rem;
   box-sizing: border-box;
   border-radius: 4px;
   background-color: transparent;
   border: 1px solid rgb(204, 204, 204);
   height: 5rem;
   color: #666666;

   &:focus {
      border: ${({ theme }) => theme && `2px solid ${theme.colors?.primary}`};
   }

   &::-ms-clear {
      display: none;
   }

   &::-ms-expand {
      display: none;
   }

   &:-moz-ui-invalid {
      box-shadow: none;
   }

   &::placeholder {
      font-weight: 400;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
   }

   &:-webkit-autofill,
   &:-webkit-autofill:hover,
   &:-webkit-autofill:focus,
   &:-webkit-autofill:active {
      transition: background-color 5000s;
      -webkit-text-fill-color: #000 !important;
   }

   &:disabled {
      background-color: hsl(0, 0%, 95%);
   }
`;

export const MobileContainer = styled.div`
   .label {
      margin-bottom: -5px;
   }
   &:focus-within {
      .label {
         color: ${({ theme }) => theme.colors?.primary};
      }
   }
   .error {
      margin: 0.1rem 0 0 0;
      color: ${({ theme }) => theme.colors?.redError};
      font-size: 1.4rem;
      letter-spacing: -0.01rem;
      font-weight: 400;
   }

   .phone-input {
      background: #fbfbfb !important;
      width: 100% !important;
      height: 48px !important;
      border: 1px solid rgb(243 242 242) !important;
      border-radius: 0;
   }

   .input-button {
      border: none !important;
      border: 1px solid rgb(243 242 242) !important;
      border-radius: 8px !important;
   }

   .input-container {
      margin-top: 8px;
      z-index: 999;
   }

   .input-container:focus-within {
      border-left: 1px solid grey;
   }
`;

export const EndAdornmentWrapperDiv = styled.div`
   position: absolute;
   left: 95%;
   padding-top: 6px;
   cursor: pointer !important;
   ${responsive("$small")`
       left: 90%;
   `}
`;
export const EndAdornmentWrapperDivRight = styled.div`
   position: absolute;
   left: 0.5rem;
   padding-top: 6px;
   cursor: pointer !important;
   /* ${responsive("$small")`
       right: 2rem ;
   `} */
`;

export const LandingInputFieldWrapperDiv = styled.div`
   position: relative;
   display: flex;
   align-items: center;
   width: 100%;
   z-index: 100;
   cursor: pointer !important;
   ${responsive("$small")`
      width:100%;
   `}
`;

export const LandingInputField = styled.input<IInputProps>`
   width: 100%;
   border: none;
   outline: none;
   font-size: 1.5rem;
   appearance: none;
   border-radius: 8px;
   padding: 0 ${({ startAdornment }) => (startAdornment ? "1.2rem" : "1rem")};
   box-sizing: border-box;
   border: 1px solid rgb(243 242 242);
   background-color: #fbfbfb;
   /* background-color: ${({ theme }) => theme.colors?.grey01}; */

   height: 4.8rem;
   cursor: pointer !important;
   color: #6d7a98;

   &:focus {
      border-left: 1px solid grey;
   }

   &::-ms-clear {
      display: none;
   }

   &::-ms-expand {
      display: none;
   }

   &:-moz-ui-invalid {
      box-shadow: none;
   }

   &::placeholder {
      font-weight: 400;
      opacity: 0.4;
   }

   &:-webkit-autofill,
   &:-webkit-autofill:hover,
   &:-webkit-autofill:focus,
   &:-webkit-autofill:active {
      transition: background-color 5000s;
      -webkit-text-fill-color: #000 !important;
   }
   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   &:disabled {
      background-color: hsl(0, 0%, 95%);
   }
`;

export const LandingInputFieldHero = styled(LandingInputField)`
   padding-left: 4rem;
`;
