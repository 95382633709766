import Select from "react-select";
import { Div, ErrorP, InputFieldWrapperDiv, Label } from "components/input/input.style";
import { schoolFilterDropdown } from "components/svgs";
import { ISelectInput } from "./i-select";
import { NewSelectInputComponentStyle } from "./select-input.styles";

const SchoolFilterSelect = ({
   label,
   error,
   banner,
   required,
   labelColor,
   key,
   margin,
   ...rest
}: ISelectInput) => {
   return (
      <Div style={{ margin: margin ? margin : 0 }}>
         <InputFieldWrapperDiv>
            <NewSelectInputComponentStyle banner={banner} error={!!error}>
               {label && (
                  <Label className="select-label" style={{ color: labelColor }}>
                     {`${label}`} <span style={{ color: "red" }}>{required ? "*" : ""}</span>
                  </Label>
               )}
               <Select
                  styles={{
                     menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  classNamePrefix="react-select"
                  className="red"
                  menuPortalTarget={typeof window !== "undefined" ? window.document.body : null}
                  components={{
                     IndicatorSeparator: () => null,
                     DropdownIndicator: () => <DropdownComponent />,
                  }}
                  {...rest}
               />
               {error && (
                  <ErrorP
                     initial="pageInitial"
                     animate="pageAnimate"
                     exit="pageExit"
                     variants={{
                        pageInitial: {
                           opacity: 1,
                           x: 0,
                           y: -5,
                        },
                        pageAnimate: {
                           opacity: 1,
                           x: 0,
                           y: 0,
                        },
                        pageExit: {
                           opacity: 0,
                        },
                     }}
                  >
                     {error}
                  </ErrorP>
               )}
            </NewSelectInputComponentStyle>
         </InputFieldWrapperDiv>
      </Div>
   );
};

const DropdownComponent = () => {
   return <div>{schoolFilterDropdown}</div>;
};

SchoolFilterSelect.defaultProps = {
   label: "",
};

export { SchoolFilterSelect };
