import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { responsive } from "theme/reponsive";

export const BannerWrapper = styled.div`
   position: relative;
   min-height: 70vh;
   background-color: #000;
   overflow: hidden;
   background-image: linear-gradient(#000000bf, #1515154a), url("/images/bg_main.webp");
   box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
   background-position: center;
   background-size: cover;
   width: 100%;
   z-index: -1;

   ${responsive("$small")`
      min-height: 450px;
   `}
`;

export const BannerTextContainer = styled.div`
   position: absolute;
   color: #fff;
   top: 0;
   left: 0;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   height: 100%;
   text-align: center;

   @media screen and (max-width: 468px) {
   }

   h1,
   h2 {
      font-size: 4.2rem;
      color: #fff;
      line-height: 1.2;
      text-shadow: 1px 1px 2px black;

      @media screen and (max-width: 768px) {
         font-size: 3rem;
      }

      @media screen and (max-width: 468px) {
         font-size: 2.7rem;
      }
   }

   // h2 {
   //    font-weight: 400;
   //    font-size: rem;
   //    margin-top: 10px;
   //    line-height: 1.8;
   //    text-shadow: 1px 1px 2px black;

   //    @media screen and (max-width: 768px) {
   //       font-size: 1.5rem;
   //    }

   //    @media screen and (max-width: 468px) {
   //       font-size: 1.5rem;
   //       margin: 10px 0;
   //    }
   // }

   p {
      font-size: 3rem;
      color: #fff;
      margin: 30px 0 10px;
      font-weight: 500;
      padding: 7px;
      position: relative;

      @media screen and (max-width: 768px) {
         font-size: 2rem;
         margin: 30px 0 5px;
      }

      @media screen and (max-width: 468px) {
         margin: 0;
         padding: 10px 0;
      }

      span {
         position: relative;
         z-index: 40;
      }

      .bg-white {
         background: #fff;
         height: 45px;
         width: 400px;
         position: absolute;
         top: 0;
         left: 40px;
         z-index: 2;
      }
   }
`;

export const FilterContainer = styled(motion.div)`
   background: #fff;
   border-radius: 4px;
   position: relative;
   top: -80px;
   z-index: 20;
   max-width: 900px;
   margin: 0 auto;
   box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
   padding: 30px;
   overflow: hidden;

   @media screen and (max-width: 768px) {
      max-width: 85%;
      margin: auto;
      top: -120px;
      padding: 25px;
   }

   @media screen and (max-width: 468px) {
      max-width: 95%;
      margin: 0 auto;
      padding: 25px;
   }

   h3 {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 1.45rem;
   }

   .circle {
      opacity: 0.4;
      position: absolute;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors?.white};
      border: 0.7rem solid ${({ theme }) => theme.colors?.primary};
   }

   .circle1 {
      top: -20px;
      right: 10px;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
   }
   .circle2 {
      bottom: -35px;
      left: 10px;
   }
`;

export const BannerContainer = styled.div``;

export const FilterItems = styled.div`
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   width: 100%;
   column-gap: 15px;
   button {
      margin: 0 auto;

      @media screen and (max-width: 768px) {
         width: 20% !important;
      }

      @media screen and (max-width: 468px) {
         width: -webkit-fill-available;
      }
   }

   @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 15px;
      flex-direction: column;
   }
`;

export const MobileFilterItems = styled.div`
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   width: 100%;
   column-gap: 30px;
   button {
      margin: 0 auto;

      @media screen and (max-width: 768px) {
         width: 20% !important;
      }

      @media screen and (max-width: 468px) {
         width: -webkit-fill-available;
      }
   }

   @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 30px;
      flex-direction: column;
   }
`;

export const SummerCampFilterItems = styled.div`
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   width: 100%;
   column-gap: 15px;
   margin-top: 20px;
   button {
      margin: 0 auto;

      @media screen and (max-width: 468px) {
         width: -webkit-fill-available;
      }
   }

   @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 15px;
      flex-direction: column;
   }
`;

export const FilterSearchParams = styled.div`
   display: flex;
   column-gap: 15px;

   @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 15px;
      flex-direction: column;
   }
`;

export const NonBannerContainer = styled.div`
   width: 100%;
   height: 100%;
   margin: 0px auto;
   background: #fefefe;

   &.bg {
      background-color: #e5e5e547;
      padding: 70px 0;
   }
`;

export const Container = styled.div`
   width: 100%;
   height: 100%;
   margin: 0 auto;
   background: #fefefe;
`;

export const CustomContainer = styled.div<{ isBanner?: boolean }>`
   padding: 0 3em;
`;

export const MainContainer = styled.div<{ isBanner?: boolean }>`
   margin: 0 auto;
   position: relative;
   padding: 0 2rem;
   max-width: 122rem;

   .more-heading {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #444444;
      margin-bottom: 32px;
      @media screen and (max-width: 768px) {
         margin-top: 200px;
      }
   }

   .comment-header {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 32px;
      margin-top: 122px;
   }
   .blog-header {
      font-weight: 600;
      font-size: 32px;
      line-height: 56px;
      margin-bottom: 33px;
      margin-top: 100px;
      @media screen and (max-width: 768px) {
         font-size: 18px;
         line-height: 24px;
         margin-bottom: 16px;
         margin-top: 100px;
      }
   }

   ${({ isBanner }) =>
      isBanner &&
      css`
         margin: 0;
         width: 100%;
         padding: 0 10rem;

         ${responsive("$large")`
            padding: 0 2rem;
         `}
      `}

   &.school {
      max-width: 118.4rem;
   }

   ${responsive("$large")`
      padding: 0 2rem;
   `}
   ${responsive("$small")`
         .search-desc {
            font-weight: 400;
            font-size:14px;
         }
   `}
`;

export const BlogContainer = styled.div<{ isBanner?: boolean }>`
   margin: 0 auto;
   .comment-button {
      margin-top: 8px;
      button {
         width: 197px;
         font-size: 18px;
      }
   }
   .blog-textarea {
      margin-top: 15px;
   }
   .blog-display {
      display: flex;
      gap: 17px;
      margin-bottom: 10px;
      margin-top: 35px;
   }
   .blog-comment {
      h5 {
         color: #666666;
         font-size: 18px;
         font-weight: 500;
         padding-top: 10px;
      }
      p {
         font-weight: 400;
         font-size: 12px;
         line-height: 18px;
         color: #706c6c;
      }
   }
   .comment-content {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #4d4d50;
   }
`;

export const LandingPageWrapper = styled.div`
   position: relative;
   min-height: 50vh;
   background-color: #3678e5;
   overflow: hidden;
   box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
   background-position: center;
   background-size: cover;
   width: 100%;
   ${responsive("$small")`
      min-height: 450px;
   `}
`;

export const FlexLandingPageWrapper = styled.div`
   display: flex;
   width: 100%;
   padding-left: 90px;
   padding-right: 83px;
   align-items: center;
   margin-top: 5vh;
   color: #fff;
   justify-content: space-between;
   .text-container {
      width: 53%;
      h1 {
         font-size: 40px;
         line-height: 50px;
         span {
            text-decoration: underline;
            text-decoration-color: #2cb57a;
         }
      }
      .auto-suggest {
         position: relative;
         .search-result {
            position: absolute;
            max-height: 130px;
            overflow-y: scroll;
            width: 400px;
            z-index: 200;
            background-color: #fff;
            border-radius: 8px;
            .result {
               padding-left: 10px;
               padding-right: 5px;
               p {
                  color: #666666;
                  font-size: 12.5px;
                  cursor: pointer;
                  font-weight: 700;
                  &:hover {
                     color: blue;
                  }
               }
            }
         }
         .search-component {
            display: flex;
            align-items: flex-end;
            margin-top: 44px;
            .search {
               display: flex;
               flex-direction: column;
               p {
                  color: #fff;
                  font-size: 16px;
                  font-weight: 500;
               }
               input {
                  width: 400px;
                  border-top-left-radius: 8px;
                  border-bottom-left-radius: 8px;
               }
            }
            button {
               background-color: #2cb57a;
               border: none;
               color: #fff;
               border-radius: 0px 8px 8px 0px;
               height: 50px;
            }
         }
      }
      .why-text {
         color: #fff;
         font-size: 16px;
         text-decoration: underline;
         font-weight: 500;
         cursor: pointer;
         margin-top: 24px;
      }
      @media screen and (max-width: 768px) {
         width: 100%;
         h1 {
            font-size: 24px;
            line-height: 32px;
            text-align: center;
         }
         h1:first-child {
            margin-top: 20px;
         }

         .search-component {
            .search {
               width: 70%;
               border-top-left-radius: 8px;
               border-bottom-left-radius: 8px;
               p {
                  font-size: 12px !important;
               }
            }
         }
         .auto-suggest {
            .search-result {
               width: 70%;
            }
         }
      }
   }
   .image-container {
      width: 45%;
      margin-bottom: -6px;
      img {
         height: 428px;
         width: 100%;
      }
      @media screen and (max-width: 768px) {
         width: 100%;
      }
   }
   @media screen and (max-width: 768px) {
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
   }
`;

export const NewAdvancedFilters = styled.div`
   position: absolute;
   left: 5%;
   top: 15%;
   z-index: 500;
   width: 90%;
   background-color: #fff;
   box-shadow: 0px 4px 4px rgba(209, 216, 222, 0.51);
   border-radius: 8px;
   height: 224px;
   @media screen and (max-width: 768px) {
      display: none;
   }
`;

export const NewFilterSearchParams = styled.div`
   display: flex;
   column-gap: 15px;

   @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 15px;
      flex-direction: column;
   }
`;

export const NewMobileFilterSearchParams = styled.div`
   display: flex;
   column-gap: 30px;
   padding: 25px;

   @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 30px;
      flex-direction: column;
   }
`;

export const NewFilterContainer = styled.div`
   padding: 30px;
   h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
   }
   .button-area {
      p {
         color: #fff;
         font-size: 16px;
         font-weight: 500;
      }
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
   }
`;

export const MobileAdvancedFilters = styled.div`
   padding: 24px;
   z-index: 5000;
   h3 {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
   }
   .select-area {
      width: 100%;
      height: 68px;
      margin-bottom: 16px;
   }
   .button-area {
      width: 100%;
      button {
         border-radius: 8px;
      }
   }
`;

export const SearchPageAdvancedFilters = styled.div`
   width: 90%;
   background-color: #fff;
   box-shadow: 0px 4px 4px rgba(209, 216, 222, 0.51);
   border-radius: 8px;
   height: 224px;
   @media screen and (max-width: 768px) {
      display: none;
   }
`;

export const CenterSearchPageFilters = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
`;

export const NewBannerContainer = styled.div`
   position: relative;
   min-height: 600px;
   overflow: hidden;
   background-image: url("/landing-page/new_banner.png");
   background-position: center;
   background-size: cover;
   width: 100%;
   margin-top: 50px;

   h1:first-child {
      color: #fff;
      margin-top: 120px;
      text-align: center;
      transition: transform 300ms ease-in;
   }
   h1:nth-child(2) {
      color: #fff;
      margin-top: 10px;
      text-align: center;
      transition: transform 320ms ease-out;
   }
   ${responsive("$small")`
      padding: 20px;
      margin-bottom: -18px;
      h1 {
         font-size:24px;
         margin-top:0px;
      }
   `}
`;

export const FlexStats = styled.div`
   display: flex;
   flex-wrap: wrap;

   @media (max-width: 1200px) {
      margin-top: -20px;
   }
`;

export const StatsBox = styled.div<{ small?: boolean; alignText?: string }>`
   flex: ${(props) => (props.small ? "0.5" : "1")};
   min-width: 0; /* Fix for some browsers not respecting flex: 1; */

   background: #46d3c2;
   margin: 0px;
   padding: 60px;
   box-sizing: border-box;
   text-align: ${(props) => (props.alignText ? props.alignText : "left")};

   /* Add solid borders to the left and right of the small box */
   border-left: ${(props) => (props.small ? "2px solid #000" : "none")};
   border-right: ${(props) => (props.small ? "2px solid #000" : "none")};
   .num {
      color: #000;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
   }

   .label {
      color: #000;
      font-size: 24px;
      font-style: italic;
      font-weight: 400;
      line-height: 21px;
      margin-top: 20px;
   }

   @media (max-width: 767px) {
      flex-basis: 100%;
      border-left: none;
      border-right: none;
      border-top: ${(props) => (props.small ? "2px solid #000" : "none")};
      border-bottom: ${(props) => (props.small ? "2px solid #000" : "none")};
      text-align: center;
   }
`;

export const FlexInfo = styled.div`
   display: flex;
   flex-wrap: wrap;
`;

export const InfoBox = styled.div<{ small?: boolean; alignText?: string }>`
   flex: ${(props) => (props.small ? "0.9" : "1")};
   min-width: 0; /* Fix for some browsers not respecting flex: 1; */
   position: relative;

   background: #264ffa;
   margin: 0px;
   padding: 60px 60px 100px;
   box-sizing: border-box;
   text-align: ${(props) => (props.alignText ? props.alignText : "left")};
   color: #fff;
   /* Add solid borders to the left and right of the small box */
   border-left: ${(props) => (props.small ? "2px solid #fff" : "none")};
   border-right: ${(props) => (props.small ? "2px solid #fff" : "none")};
   .flex-header {
      display: flex;
      gap: 10px;
   }

   .info-image {
      width: 49px;
      height: 49px;
   }

   .info-header {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 80% */
   }
   .description {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      margin-top: 10px;
   }

   .info-section {
      button {
         margin-top: 20px;
         border-radius: 8px;
         padding: 10px;
         position: absolute;
         bottom: 15%;
      }
   }

   @media (max-width: 767px) {
      flex-basis: 100%;
      border-left: none;
      border-right: none;
      border-top: ${(props) => (props.small ? "2px solid #fff" : "none")};
      border-bottom: ${(props) => (props.small ? "2px solid #fff" : "none")};
      padding: 80px 20px;

      .info-section {
         button {
            bottom: 5%;
         }
      }
   }
`;

export const SearchResultContainer = styled.div`
   background-color: #f8f8f8;
   border-radius: 8px;
   position: absolute;
   left: 0;
   width: 100%;
   right: 0;
   top: 100%;
   z-index: 300;
`;

export const CenterSearchResult = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   .result-main {
      width: 100%;
      max-height: 350px;
      padding: 12px;
      overflow-y: scroll;
   }
   .result {
      padding: 10px;
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;
      text-align: left;
   }

   a {
      color: #434249;
      text-transform: capitalize;
      &:hover {
         opacity: 0.8;
      }
   }
`;

export const SearchMainContainer = styled.div`
   position: relative;
`;
