/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { GetServerSideProps } from "next";
import { AppContainer } from "container/app-container";
import Ads from "container/landing-page/new/ads";
import DownloadApp from "container/landing-page/new/download";
import Features from "container/landing-page/new/features";
import Featured from "container/landing-page/new/filter-featured";
import HeroSection from "container/landing-page/new/hero";
import StatsBar from "container/landing-page/new/stats-bar";
import Testimonial from "container/landing-page/new/testimonials";
import { NonDashboardWrapper } from "container/non-dashboard-wrapper";
import { unSecuredBlogs } from "services/blog";
import { fetchSchools, getSchoolsStatsTotal } from "services/school";
import { fetchAllTestimonials } from "services/testimonial";
import { commafy } from "utils/generic-utils";

// import { schoolCategory, schoolType } from "utils/schoolFormOptions";

export default function Home({
   // blogs,
   featuredSchools,
   testimonials,
   // discountedSchools,
   schoolStats,
}) {
   const pageStats = [
      {
         num: commafy(schoolStats?.totalEnquiry),
         label: "Students enrolled",
      },
      {
         num: commafy(schoolStats?.totalSchools),
         label: "Schools onboarded",
      },
      {
         num: "500k",
         label: "Community of parents",
      },
   ];

   return (
      <AppContainer
         title="Education Marketplace for Best Schools, Parents, Students, Lenders & More"
         slug={typeof window !== "undefined" ? window.location.href : ""}
         description="Welcome to Africa's largest education marketplace for best schools, parents, students, vendors, lenders and many more"
         image="/activated/seo-img.png"
      >
         <NonDashboardWrapper>
            <HeroSection />
            <StatsBar pageStats={pageStats} />
            <Features />
            <DownloadApp />
            <Featured featuredSchools={featuredSchools} />
            <Testimonial testimonials={testimonials} />
            <Ads />
         </NonDashboardWrapper>
      </AppContainer>
   );
}

export const getServerSideProps: GetServerSideProps = async () => {
   const getBlogs = async () => {
      try {
         const res = await unSecuredBlogs({});
         return res;
      } catch (error) {
         return null;
      }
   };

   const getFeaturedSchools = async () => {
      try {
         const res = await fetchSchools({ isFeatured: "yes", limit: 20, country: "nigeria" });
         return res;
      } catch (error) {
         return null;
      }
   };

   const getAllSchools = async () => {
      try {
         const res = await getSchoolsStatsTotal();
         return res;
      } catch (error) {
         return null;
      }
   };

   const getDiscountedSchools = async () => {
      try {
         const res = await fetchSchools({ hasDiscount: "yes", limit: 20, country: "nigeria" });
         return res;
      } catch (error) {
         return null;
      }
   };

   const getTestimonials = async () => {
      try {
         const res = await fetchAllTestimonials();
         return res;
      } catch (error) {
         return null;
      }
   };

   const values = await Promise.allSettled([
      getBlogs(),
      getFeaturedSchools(),
      getDiscountedSchools(),
      getTestimonials(),
      getAllSchools(),
   ]);

   const blogResponse = (values[0] as any)?.value;
   const featuredSchoolsResponse = (values[1] as any)?.value;
   const discountedSchoolsResponse = (values[2] as any)?.value;
   const testimonialsData = (values[3] as any)?.value;
   const schoolStatsData = (values[4] as any)?.value;

   return {
      props: {
         featuredSchools: (featuredSchoolsResponse as any)?.result?.data?.schools || null,
         discountedSchools: (discountedSchoolsResponse as any)?.result?.data?.schools || null,
         testimonials: (testimonialsData as any)?.result?.data.testimonials || null,
         blogs: (blogResponse as any)?.data?.data?.blogPosts || null,
         schoolStats: (schoolStatsData as any)?.data?.data || null,
      },
   };
};
