import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "next/image";
import { useForm } from "react-hook-form";
import { IoSearchOutline } from "react-icons/io5";
import ReactMarkdown from "react-markdown";
import { useMutation } from "react-query";
import * as yup from "yup";
import { Button } from "components/button";
import { Div } from "components/input/input.style";
import LandingPageSearch from "components/search/LandingPageSearch";
import { askAI } from "services/school";
import {
   AISuccessBtnContainer,
   ErrorMessageAI,
   MarkdownContainer,
   SearchAnsContainer,
   SkeletonCard,
   SkeletonContent,
   SkeletonTitle,
   SuccessAnswer,
   SuccessQuestion,
} from "styles/ask-ai/styles";
import * as S from "styles/index/new-index.style";
// import { Search } from 'lucide-react';
// import { FaSearch } from "react-icons/fa";
// import { Button } from "components/button";
// import { HeroContainer } from 'styles/index/new-index.style';

const HeroSection: React.FC = () => {
   // const { data, error, isSuccess } = useQuery(["single-school", slug], () =>
   // getSingleSchoolClient(slug)
   // );
   // const { school } = isSuccess && data?.data?.data;
   const [searchQuery, setSearchQuery] = useState("");

   const {
      mutate,
      data: answer,
      isSuccess: isSuc,
      isLoading,
      reset,
   } = useMutation({
      mutationFn: (newTodo) => {
         return askAI(newTodo);
      },
   });

   const schema = yup.object().shape({
      question: yup.string().required("Please type in a prompt"),
   });
   const {
      handleSubmit,
      formState: { errors },
      trigger,
      register,
      // setValue,
      watch,
   } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

   useEffect(() => {
      const subscription = watch((value) => {
         setSearchQuery(value.question + "?");
      });
      return () => subscription.unsubscribe();
   }, [setSearchQuery, watch]);

   const onSubmit = async (values) => {
      try {
         await schema.validateAt("question", values);
         values.question = values.question + " ?";
         mutate(values);
         console.log(searchQuery);
      } catch (error) {
         trigger();
         console.log(error);
      }
   };

   return (
      <S.HeroContainer>
         <S.InnerHeroContainer>
            <S.HeroContent>
               <S.HeroTitle>
                  <span>Edusko</span> Matches Children with Unlimited Educational Opportunities
               </S.HeroTitle>

               <S.HeroSubtitle>
                  Discover top schools, access financing & find the resources you need to unlock the
                  best education for your child - all in one place
               </S.HeroSubtitle>

               <div style={{ position: "relative" }}>
                  <h3>Ask our AI about enrollment today</h3>
                  <S.SearchContainer onSubmit={handleSubmit(onSubmit)}>
                     <S.SearchInput>
                        <LandingPageSearch
                           // handleSearch={(val) => findSchools(val)}
                           // setShowSearch={setIsSearch}
                           // searchVal={searchVal}
                           // setSearchVal={setSearchVal}
                           id="landing-page"
                           showIcon
                           placeholder="Ask about any school, rating, budget, curriculum, enrollment...."
                           {...register("question")}
                           disabled={isLoading}
                        />
                     </S.SearchInput>

                     <S.SearchButton variant="primary" type="submit" disabled={isLoading}>
                        <S.SearchButtonSpan>
                           <IoSearchOutline size={20} />
                           <span>Search</span>
                        </S.SearchButtonSpan>
                     </S.SearchButton>
                  </S.SearchContainer>
                  <ErrorMessageAI>{errors?.question?.message}</ErrorMessageAI>

                  {!isLoading && isSuc && (
                     <Div
                        style={{
                           background: "white",
                           position: "absolute",
                           top: "12.5rem",
                           zIndex: 100,
                           borderRadius: ".5rem",
                        }}
                     >
                        <SearchAnsContainer>
                           <SuccessQuestion>
                              <span>Answer:</span>
                              {/* {searchQuery}? */}
                           </SuccessQuestion>
                           <SuccessAnswer style={{ background: "white" }}>
                              <MarkdownRenderer content={answer?.data?.data} />
                           </SuccessAnswer>
                        </SearchAnsContainer>
                        <AISuccessBtnContainer>
                           <Button variant="primary" onClick={() => reset()}>
                              Ask another question
                           </Button>
                           {/* <Link href={`/school/${slug}?apply=yes`} passHref> */}
                           <Button variant="outline">Apply to school</Button>
                           {/* </Link> */}
                        </AISuccessBtnContainer>
                     </Div>
                  )}

                  {isLoading && !isSuc && <SkeletonLoading />}
               </div>
            </S.HeroContent>

            <S.HeroImage>
               <Image
                  src="/images/edusko_hero_img.png"
                  alt="Happy student"
                  width={600}
                  height={600}
                  priority
               />
            </S.HeroImage>
         </S.InnerHeroContainer>
      </S.HeroContainer>
   );
};

interface MarkdownRendererProps {
   content: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => {
   return (
      <MarkdownContainer>
         <ReactMarkdown>{content}</ReactMarkdown>
      </MarkdownContainer>
   );
};

//    return (
//       <SkeletonWrapper>
//          <SkeletonElement /> {/* Image Placeholder */}
//          <SkeletonElement /> {/* Title Placeholder */}
//          <SkeletonElement /> {/* Subtitle Placeholder */}
//          <SkeletonElement /> {/* Text Placeholder */}
//          <SkeletonElement /> {/* Text Placeholder */}
//       </SkeletonWrapper>
//    );
// };
const SkeletonLoading = () => {
   return (
      <SkeletonCard>
         <SkeletonTitle />
         <SkeletonContent />
         <SkeletonContent />
         <SkeletonContent />
         {/* <SkeletonButton /> */}
      </SkeletonCard>
   );
};

export default HeroSection;
