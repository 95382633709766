import React from "react";
import Image from "next/image";
import { BsArrowRight } from "react-icons/bs";
import * as S from "styles/index/new-index.style";

const Features = () => {
   return (
      <S.FeaturesContainer>
         <S.FeaturesInnerContainer>
            <FeatureCard
               tag="Enrollment"
               title="Best Schools are a Click Away!"
               description="Find, compare & enroll in leading schools tailored to your child's needs. Leveraging advanced algorithm, we match children with the right schools regardless of their background."
               imageSrc="/images/feature-1-img.png"
               imageAlt="Student with glasses smiling"
               url="/signup"
            />

            <FeatureCard
               tag="Education Finance"
               title="Flexible Education Financing"
               description="Get the support you need to make quality education affordable. With flexible terms and a hassle-free process, you can access our affordable tuition financing options."
               imageSrc="/images/feature-2-img.png"
               imageAlt="Student holding pencil"
               url="/education-finance"
            />
         </S.FeaturesInnerContainer>
      </S.FeaturesContainer>
   );
};

interface FeatureCardProps {
   tag: string;
   title: string;
   description: string;
   imageSrc: string;
   imageAlt: string;
   url: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
   tag,
   title,
   description,
   imageSrc,
   imageAlt,
   url,
}) => (
   <S.FeatureCard>
      <S.FeatureImage>
         <Image
            src={imageSrc}
            alt={imageAlt}
            layout="fill"
            style={{ objectFit: "cover", objectPosition: "center" }}
            priority
         />
      </S.FeatureImage>

      <S.FeatureContent>
         <S.FeatureTag>{tag}</S.FeatureTag>
         <S.FeatureTitle>{title}</S.FeatureTitle>
         <S.FeatureDescription>{description}</S.FeatureDescription>
         <S.LearnMoreButton href={url}>
            Learn More
            <span>
               <BsArrowRight color="white" size={16} />
            </span>
         </S.LearnMoreButton>
      </S.FeatureContent>
   </S.FeatureCard>
);

export default Features;
