import * as S from "styles/index/new-index.style";
import { HomePageAdvert } from "../home-page-advert";

const Ads = () => {
   return (
      <S.AdsContainer>
         <S.AdsInnerContainer>
            <HomePageAdvert />
         </S.AdsInnerContainer>
      </S.AdsContainer>
   );
};

export default Ads;
