import React, { FC } from "react";
// import Footer from "components/footer/footer";
import { Navbar } from "components/navbar";
import Footer from "container/landing-page/new/footer";
import { NonDashboardWrapperChildren } from "./non-dashboard-wrapper.style";

const NonDashboardWrapper: FC<any> = ({ children, style }: any) => {
   return (
      <div>
         <Navbar />
         <NonDashboardWrapperChildren style={style || {}}>{children}</NonDashboardWrapperChildren>
         <Footer />
      </div>
   );
};

export { NonDashboardWrapper };
