import { ChangeEvent, forwardRef } from "react";
// import SearchOutlined from "@material-ui/icons/SearchOutlined";
import { useDebouncedCallback } from "use-debounce";
import { IInputProps } from "components/input/i-input";
import {
   // EndAdornmentWrapperDiv,
   LandingInputFieldWrapperDiv,
   // LandingInputField,
   EndAdornmentWrapperDivRight,
   LandingInputFieldHero,
} from "components/input/input.style";
import { LandingSearchWrapper } from "./Search.style";

const LandingPageSearch = forwardRef<HTMLInputElement, IInputProps>(
   (
      {
         id,
         name,
         type = "text",
         value,
         fullWidth,
         className,
         onChange,
         startAdornment,
         isMobile,
         setShowSearch,
         handleSearch,
         placeholder,
         isLeft,
         searchVal,
         setSearchVal,
         showIcon,
         ...remainingProps
      },
      ref
   ) => {
      // const [searchVal, setSearchVal] = useState("");

      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
         const { value } = event.target;
         debounced(value);
         setSearchVal && setSearchVal(() => value);
      };

      const debounced = useDebouncedCallback(
         // function
         (searchValue) => {
            if (searchValue.length > 0) return handleSearch && handleSearch(searchVal);
            setShowSearch && setShowSearch(false);
         },
         // delay in ms
         600
      );
      return (
         <LandingSearchWrapper>
            <LandingInputFieldWrapperDiv>
               <EndAdornmentWrapperDivRight>
                  {showIcon && (
                     <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M27.6172 10.8373L27.2892 11.592C27.2379 11.7149 27.1514 11.8199 27.0406 11.8937C26.9298 11.9676 26.7997 12.007 26.6665 12.007C26.5334 12.007 26.4032 11.9676 26.2924 11.8937C26.1816 11.8199 26.0951 11.7149 26.0438 11.592L25.7158 10.8373C25.1391 9.50231 24.083 8.43191 22.7558 7.83735L21.7438 7.38535C21.6211 7.32887 21.5171 7.23838 21.4441 7.12459C21.3712 7.0108 21.3325 6.87849 21.3325 6.74335C21.3325 6.60821 21.3712 6.4759 21.4441 6.36211C21.5171 6.24832 21.6211 6.15783 21.7438 6.10135L22.6998 5.67602C24.0604 5.06452 25.1346 3.95453 25.7012 2.57468L26.0385 1.76002C26.088 1.63382 26.1745 1.52548 26.2865 1.44912C26.3985 1.37275 26.5309 1.33191 26.6665 1.33191C26.8021 1.33191 26.9345 1.37275 27.0465 1.44912C27.1585 1.52548 27.245 1.63382 27.2945 1.76002L27.6318 2.57335C28.1978 3.95345 29.2716 5.06393 30.6318 5.67602L31.5892 6.10268C31.7116 6.15932 31.8152 6.24981 31.8878 6.36345C31.9605 6.47709 31.9991 6.60915 31.9991 6.74402C31.9991 6.87889 31.9605 7.01094 31.8878 7.12459C31.8152 7.23823 31.7116 7.32871 31.5892 7.38535L30.5758 7.83602C29.2489 8.43117 28.1933 9.50205 27.6172 10.8373ZM26.6665 14.6667C27.5715 14.6674 28.47 14.5146 29.3238 14.2147C29.3301 14.3658 29.3332 14.5165 29.3332 14.6667C29.3332 17.4957 28.2094 20.2088 26.209 22.2092C24.2086 24.2095 21.4955 25.3333 18.6665 25.3333V30C11.9998 27.3333 2.6665 23.3333 2.6665 14.6667C2.6665 11.8377 3.79031 9.1246 5.7907 7.12421C7.79109 5.12382 10.5042 4.00002 13.3332 4.00002H18.6665C18.8176 4.00002 18.9683 4.00313 19.1185 4.00935C18.6936 5.21592 18.5642 6.50678 18.7412 7.77367C18.9182 9.04056 19.3965 10.2465 20.1358 11.2904C20.8751 12.3343 21.854 13.1857 22.9903 13.7732C24.1267 14.3606 25.3873 14.667 26.6665 14.6667Z"
                           fill="#264FFA"
                        />
                     </svg>
                  )}
               </EndAdornmentWrapperDivRight>
               <LandingInputFieldHero
                  id={id}
                  ref={ref}
                  name={name}
                  type={type}
                  value={value}
                  onChange={handleChange}
                  startAdornment
                  isMobile={isMobile}
                  placeholder={placeholder || "Search"}
                  {...remainingProps}
               />
            </LandingInputFieldWrapperDiv>
         </LandingSearchWrapper>
      );
   }
);
export default LandingPageSearch;
