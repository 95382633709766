import styled from "styled-components";

export const AfredSelectInputComponentStyle = styled.div<{
   error?: boolean;
   banner?: boolean;
   maxWidth?: string;
}>`
   width: 100%;
   max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};

   .react-select__value-container
      .react-select__value-container--is-multi
      .css-g1d714-ValueContainer {
      &:focus-visible {
         outline: none;
         border: 1px solid transparent;
      }
   }

   .react-select__value-container {
      width: 100%;
      min-height: 4.8rem;
   }

   .react-select__control {
      /* background-color: ${({ theme, banner }) => (banner ? "#fff" : theme.colors?.grey01)}; */
      background-color: #f0f0f0;
      min-height: 4.8rem;
      width: 100%;
      font-size: 1.5rem;
      color: #666666;
      border-color: #666666;
      border-width: 0.5px;
      /* border: ${({ banner }) => (banner ? "" : "1px solid rgb(243 242 242)")}; */
   }

   .react-select__placeholder {
      font-weight: 400;
      color: #666666;
   }

   .react-select__indicator-seperator {
      display: none !important;
   }

   .react-select__single-value {
      font-weight: 400;
      color: #666666;
      /* color: ${({ theme }) => theme.colors?.black}; */
   }

   .react-select__multi-value__label {
      background: rgb(255 255 255 / 58%);
      color: #666666;
   }

   .react-select__multi-value {
      background: #f0f0f0;
      color: #666666;
      border-color: #666666;
      border-width: 0.5px;
      margin: 5px 8px;

      &:not(:first-child) {
         margin-left: 8px;
      }
   }

   .react-select-container {
      position: relative !important;
   }

   .react-select__menu {
      top: 50px !important;
      z-index: 999999;
   }
`;

export const SelectInputComponentStyle = styled.div<{
   error?: boolean;
   banner?: boolean;
   maxWidth?: string;
}>`
   width: 100%;
   max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};

   .react-select__value-container
      .react-select__value-container--is-multi
      .css-g1d714-ValueContainer {
      &:focus-visible {
         outline: none;
         border: 1px solid transparent;
      }
   }

   .react-select__value-container {
      width: 100%;
      min-height: 4.8rem;
   }

   .react-select__control {
      background-color: ${({ theme, banner }) => (banner ? "#fff" : theme.colors?.grey01)};
      min-height: 4.8rem;
      width: 100%;
      font-size: 1.5rem;
      color: #dadada;
      border: ${({ banner }) => (banner ? "" : "1px solid rgb(243 242 242)")};
   }

   .react-select__placeholder {
      font-weight: 400;
      color: #8fa7bc;
   }

   .react-select__indicator-seperator {
      display: none !important;
   }

   .react-select__single-value {
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.black};
   }

   .react-select__multi-value__label {
      background: rgb(255 255 255 / 58%);
      color: #007eff;
   }

   .react-select__multi-value {
      background: rgba(0, 126, 255, 0.08);
      color: #007eff;
      border: 1px solid rgba(0, 126, 255, 0.24);
      margin: 5px 8px;

      &:not(:first-child) {
         margin-left: 8px;
      }
   }

   .react-select-container {
      position: relative !important;
   }

   .react-select__menu {
      top: 50px !important;
      z-index: 999999;
   }
`;

export const NewSelectInputComponentStyle = styled.div<{
   error?: boolean;
   banner?: boolean;
   maxWidth?: string;
   height?: string;
}>`
   background: #fff;
   border: 1px solid #a5adc0;
   padding: 11px 16px;
   border-radius: 4px;
   width: 100%;
   height: ${({ height }) => (height ? height : "64px")};
   max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};

   .select-label {
      line-height: 16px !important;
      font-size: 12px !important;
      font-weight: 500;
      margin-bottom: 2px;
   }

   .react-select__value-container
      .react-select__value-container--is-multi
      .css-g1d714-ValueContainer {
      &:focus-visible {
         outline: none;
         border: none;
      }
   }

   .react-select__value-container {
      width: 100%;
      padding: 0;
      min-height: 2.4rem;
      menuportal: (base) => ({...base, zIndex: 9999});
   }

   .react-select__control {
      background-color: transparent;
      min-height: 2.4rem;
      width: 100%;
      font-size: 1.6rem;
      line-height: 24px;
      color: #151f34;
      border: none;
      box-shadow: none;
   }

   .react-select__placeholder {
      font-weight: 400;
      color: #151f34;
      line-height: 24px;
   }

   .react-select__value-container {
      font-weight: 400;
      height: 2.4rem;
   }

   .react-select__indicator-seperator {
      display: none !important;
   }

   .react-select__indicators {
      height: 2.4rem;
      width: 2.4rem;
   }

   .react-select__indicatorContainer {
      padding: 0;
   }

   .react-select__single-value {
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.black};
   }

   .react-select__multi-value__label {
      background: rgb(255 255 255 / 58%);
      color: #007eff;
   }

   .react-select__multi-value {
      background: rgba(0, 126, 255, 0.08);
      color: #007eff;
      border: 1px solid rgba(0, 126, 255, 0.24);
      margin: 5px 8px;

      &:not(:first-child) {
         margin-left: 8px;
      }
   }
`;

export const SelectInputWrapper = styled.div`
   width: 200px;
`;
