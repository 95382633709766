import { useState } from "react";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Link from "next/link";
import { useRouter } from "next/router";
import ReactFlagsSelect from "react-flags-select";
// import { BrandImage } from "components/brand-image";
import { Button } from "components/button";
import { getSession } from "utils/auth";
import { MobileUserProfileMenu } from "./mobile-user-profile-menu";
import {
   NavLink,
   NavLinks,
   OtherLinks,
   UserDetailsSection,
   MobileNavContainer,
   RadiusButton,
   CountryLinks,
} from "./navbar.style";

interface IMobileNav {
   menuActive: boolean;
   userProfileMenuClicked: boolean;
   setUserProfileMenuClicked?: any;
   setNavIconClicked?: any;
}

const MobileNav = ({
   menuActive,
   userProfileMenuClicked,
   setUserProfileMenuClicked,
}: IMobileNav) => {
   const router = useRouter();
   const initialCountry = (path) => {
      switch (true) {
         case path.pathname === "/":
            return "NG";
         case path.pathname === "/ghana":
            return "GH";
         case path.pathname === "/kenya":
            return "KE";

         default:
            return;
      }
   };

   const { inSession, logout, user } = getSession();
   const [select, setSelect] = useState<any>(initialCountry(router));

   function handleSchoolRegistration() {
      if (inSession) {
         router.push("/admin/school/add");
      } else {
         router.push({
            pathname: "/signup",
            query: {
               type: "sch_admin",
            },
         });
      }
   }

   const onSelect = (code) => {
      setSelect(code);
      switch (true) {
         case code === "NG":
            return router.push("/");
         case code === "GH":
            return router.push("/ghana");
         case code === "KE":
            return router.push("/kenya");

         default:
            return;
      }
   };

   return (
      <>
         <MobileNavContainer menuActive={menuActive} className="navbaritems">
            <>
               {user && (
                  <UserDetailsSection
                     onClick={() => (user ? setUserProfileMenuClicked(true) : () => {})}
                  >
                     <>
                        <div className="user-content-info">
                           <span>
                              {`${user?.firstName?.charAt(0).toUpperCase()} ${user?.lastName
                                 ?.charAt(0)
                                 .toUpperCase()}`}
                           </span>
                           <div className="user-info">
                              <p className="user-name">
                                 Hi, {user?.firstName} {user?.lastName}
                              </p>
                              <p className="greeting">Welcome Back</p>
                           </div>
                        </div>
                        <ChevronRight className="right-icon" />
                     </>
                  </UserDetailsSection>
               )}
               <NavLinks notAuth={!!!user}>
                  <Link href="/study-abroad" passHref>
                     <NavLink className={router.pathname === "/study-abroad" ? "active" : ""}>
                        Study Abroad
                     </NavLink>
                  </Link>
                  <Link href="/compare-schools" passHref>
                     <NavLink className={router.pathname === "/compare-schools" ? "active" : ""}>
                        Compare Schools
                     </NavLink>
                  </Link>
                  <NavLink onClick={() => router.push(`/education-finance`)}>
                     Education Finance
                  </NavLink>

                  <Link href="/blog" passHref>
                     <NavLink className={router.pathname === "/blog" ? "active" : ""}>
                        Blogs
                     </NavLink>
                  </Link>
               </NavLinks>

               <CountryLinks>
                  <ReactFlagsSelect
                     selected={select}
                     onSelect={onSelect}
                     countries={["NG", "GH", "KE"]}
                     showSelectedLabel={false}
                     fullWidth={false}
                     showOptionLabel={false}
                     selectedSize={25}
                     optionsSize={25}
                     placeholder="x"
                  />
               </CountryLinks>

               <OtherLinks>
                  <RadiusButton>
                     <Button
                        fullWidth
                        variant="new_primary"
                        // size="small"
                        onClick={handleSchoolRegistration}
                     >
                        List Your School
                     </Button>
                  </RadiusButton>
                  <div style={{ marginTop: "25px", width: "100%" }}>
                     {inSession ? (
                        <Button fullWidth variant="secondary" size="small" onClick={logout}>
                           Logout
                        </Button>
                     ) : (
                        <RadiusButton>
                           <Button
                              fullWidth
                              variant="outline"
                              // size="small"
                              onClick={() => router.push("/signin")}
                           >
                              Login
                           </Button>
                        </RadiusButton>
                     )}
                  </div>
               </OtherLinks>
            </>
            {/* <div className="bottom-brand">
               <BrandImage />
            </div> */}
            <MobileUserProfileMenu
               userProfileMenuClicked={userProfileMenuClicked}
               setUserProfileMenuClicked={setUserProfileMenuClicked}
            />
         </MobileNavContainer>
      </>
   );
};

export { MobileNav };
