import styled, { css } from "styled-components";
import { IButtonEmotionProps } from "./i-button";

function variantButtonStyles({ theme, variant }: IButtonEmotionProps) {
   switch (variant) {
      case "secondary":
         return css`
            color: #42425f;
            background-color: #fff;
            border: 1px solid ${theme.colors?.grey};

            &:hover,
            &:active {
               opacity: 0.7;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               border-color: ${theme.colors?.grey};
               background-color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      case "invert":
         return css`
            color: #fff;
            background-color: transparent;
            border: 1px solid ${theme.colors?.white};

            &:hover,
            &:active {
               opacity: 0.7;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               border-color: ${theme.colors?.grey};
               background-color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      case "grey":
         return css`
            color: ${theme.colors?.white};
            background-color: #42425f;
            border: 1px solid ${theme.colors?.grey};

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               border-color: ${theme.colors?.grey};
               background-color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      case "unstyled":
         return css`
            color: transparent;
            background-color: ${theme.colors?.primary};
            border: none;
            text-decoration: none;

            &:hover,
            &:active {
               opacity: 0.7;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.white};
               border-color: ${theme.colors?.grey};
               background-color: ${theme.colors?.primary};
               cursor: not-allowed;
               opacity: 0.4;
            }
         `;
      case "link":
         return css`
            color: ${theme.colors?.primary};
            background: none;
            font-weight: 500;
            padding-left: 0;
            text-decoration: none;
            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:focus {
               outline: 0;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      case "outline":
         return css`
            color: ${theme.colors?.primary};
            background: none;
            font-weight: 500;
            border: 1px solid ${theme.colors?.primary};

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      case "new_outline":
         return css`
            color: #3678e5;
            background: none;
            font-weight: 500;
            border: 1px solid #3678e5;

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      case "grey_outline":
         return css`
            color: ${theme.colors?.grey};
            background: none;
            font-weight: 500;
            border: 1px solid ${theme.colors?.grey};

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      case "grey-link":
         return css`
            color: ${theme.colors?.grey};
            background: none;
            font-weight: 500;
            padding-left: 0;
            text-decoration: underline;

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:focus {
               outline: 0;
            }
         `;
      case "danger":
         return css`
            color: ${theme.colors?.danger};
            background-color: #fff;
            border: 1px solid ${theme.colors?.danger};

            &:hover,
            &:active {
               opacity: 0.7;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               border-color: ${theme.colors?.grey};
               background-color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      case "success":
         return css`
            color: #000;
            background-color: #46d3c2;
            border: none;

            &:hover,
            &:active {
               opacity: 0.7;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               border-color: ${theme.colors?.grey};
               background-color: #46d3c2;
               cursor: not-allowed;
            }
         `;
      case "new_primary":
         return css`
            color: ${theme.colors?.white};
            background-color: #3678e5;
            &:hover,
            &:active {
               color: ${theme.colors?.white};
               background-color: ${theme.colors?.primary};
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.white};
               border-color: ${theme.colors?.grey};
               background-color: ${theme.colors?.primary};
               cursor: not-allowed;
               opacity: 0.4;
            }
         `;
      case "outline-white":
         return css`
            color: #fff;
            background: none;
            font-weight: 500;
            text-decoration: none;

            border: 1px solid #fff;

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey};
               cursor: not-allowed;
            }
         `;
      default:
      case "primary":
         return css`
            color: ${theme.colors?.white};
            background-color: ${theme.colors?.primary};

            &:hover,
            &:active {
               color: ${theme.colors?.white};
               background-color: ${theme.colors?.primary};
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.white};
               border-color: ${theme.colors?.grey};
               background-color: ${theme.colors?.primary};
               cursor: not-allowed;
               opacity: 0.4;
            }
         `;
   }
}

export const ButtonStyledComponent = styled.button`
   border: 0;
   padding: 0;
   cursor: pointer;
   box-sizing: border-box;
   display: inline-block;
   max-width: 100%;
   text-align: center;
   overflow: hidden;
   width: ${({ width }) => width};
   white-space: nowrap;
   text-overflow: ellipsis;
   font-size: ${({ size }) => (size === "small" ? "1.4rem" : "1.5rem")};
   letter-spacing: 0;
   line-height: 1.6rem;
   font-weight: ${({ theme }) => theme.colors?.transparent};
   border-radius: 8px;
   /* text-transform: capitalize; */
   transition: all 300ms ease-in-out;
   background-color: ${({ theme }) => theme.colors?.grey};
   color: ${({ theme }) => theme.colors?.grey};
   font-weight: 500;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: ${({ size }) => {
      switch (size) {
         case "small":
            return ".75rem 1.75rem;";
         default:
            return "1.4rem 2.4rem;";
      }
   }};

   ${({ fullWidth }) =>
      fullWidth &&
      css`
         width: 100%;
      `};

   ${variantButtonStyles}
`;
